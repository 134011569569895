import { Recommendations } from '../../../../../components/login/Recommendations';
import { Godsons } from '../../../../../components/login/Godsons';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../../../../hooks/useApi';
import { Link } from 'react-router-dom';
import { useTranslate } from '../../../../../../../shared/hooks/useTranslate';
import { User } from '@invityou/types';
import { Godson, Recommendation } from '@invityou/types/bin/api/Users';
import { useAbsolutePath } from '../../../../../hooks/useAbsolutePath';
import { useAuth } from '../../../../../hooks/useAuth';
import { useCompanionsOptionsQuery } from '../../../../../hooks/magicform/useCompanionsOptionsQuery';

export default function GodsonsAndRecommendations() {
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();
	const api = useApi();
	const { user } = useAuth();

	const userId = user?.id;

	const companionsOptionsQuery = useCompanionsOptionsQuery(userId);

	const options = {
		expand: 'godsons',
	};

	const query = useQuery({
		queryKey: ['userAndRecommendations', options, userId],
		queryFn: async () => {
			if (!userId) {
				return null;
			}

			const [{ data: user }, { data: recommendations }]: [
				{ data: User },
				{ data: Recommendation[] },
			] = await Promise.all([
				api.fetchUser(userId, options),
				api.getRecommendations(userId),
			]);

			return { user: user, recommendations: recommendations };
		},
		enabled: !!userId,
	});

	if (query.isPending || companionsOptionsQuery.isPending) {
		return (
			<section className="section-long">
				<div className="spinner-border text-primary" role="status">
					<span className="sr-only">{__('Loading...')}</span>
				</div>
			</section>
		);
	}

	return (
		<section className="section-long">
			<div className="container section-container">
				{query.data &&
					companionsOptionsQuery.data &&
					companionsOptionsQuery.data.displayGodsons && (
						<Godsons godsons={query.data.user.godsons as Godson[]} />
					)}
				{query.data &&
					companionsOptionsQuery.data &&
					companionsOptionsQuery.data.displayRecommendations && (
						<Recommendations recommendations={query.data.recommendations} />
					)}

				<div style={{ marginTop: 20, textAlign: 'right' }}>
					<Link
						to={`${prefix}/register#start`}
						className="btn btn-secondary pull-left"
					>
						{__('Back to form')}
					</Link>
					<Link to={`${prefix}/ticketing#start`} className="btn btn-primary">
						{__('Continue')}
					</Link>
				</div>
			</div>
		</section>
	);
}

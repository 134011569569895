import { useForm } from 'react-hook-form';
import { useTranslate } from '../../../../shared/hooks/useTranslate';

export interface CouponFormData {
	coupon: string;
}

interface Props {
	onSubmit: (values: CouponFormData) => void;
}

const CouponForm = ({ onSubmit }: Props) => {
	const { register, handleSubmit } = useForm({ defaultValues: { coupon: '' } });
	const { __ } = useTranslate();
	return (
		<form onSubmit={handleSubmit(onSubmit)} className="form-inline">
			<div className="form-group">
				<input
					type="text"
					id="coupon"
					className="form-control"
					{...register('coupon')}
				/>
			</div>
			<div className="form-group">
				<button className="btn btn-ticketing-1 btn-secondary" type="submit">
					{__('Add coupon')}
				</button>
			</div>
		</form>
	);
};

export default CouponForm;

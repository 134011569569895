import { useTranslate } from '../../../shared/hooks/useTranslate';

export const DropHighlight = ({
	position,
	color,
	hideText = false,
}: {
	position: 'top' | 'bottom' | 'left' | 'right';
	color: string;
	hideText?: boolean;
}) => {
	const { __ } = useTranslate();

	const axis =
		position === 'top' || position === 'bottom' ? 'vertical' : 'horizontal';

	return axis === 'vertical' ? (
		<>
			<div
				style={{
					position: 'absolute',
					top: position === 'top' ? 0 : undefined,
					bottom: position === 'bottom' ? 0 : undefined,
					left: 0,
					right: 0,
					borderTop: `5px solid ${color}`,
					pointerEvents: 'none',
					zIndex: 100,
				}}
			></div>
			{!hideText && (
				<div
					style={{
						position: 'absolute',
						top: position === 'top' ? 0 : undefined,
						bottom: position === 'bottom' ? 0 : undefined,
						left: '50%',
						transform:
							position === 'top'
								? 'translate(-50%, -50%)'
								: 'translate(-50%, 50%)',
						pointerEvents: 'none',
						fontSize: 12,
						padding: '5px 10px',
						backgroundColor: color,
						borderRadius: 10,
						color: '#FFFFFF',
						whiteSpace: 'nowrap',
						zIndex: 100,
					}}
				>
					{__('Drag here')}
				</div>
			)}
		</>
	) : (
		<>
			<div
				style={{
					position: 'absolute',
					top: 0,
					bottom: 0,
					left: position === 'left' ? 0 : undefined,
					right: position === 'right' ? 0 : undefined,
					borderLeft: `5px solid ${color}`,
					pointerEvents: 'none',
					zIndex: 100,
				}}
			></div>
			{!hideText && (
				<div
					style={{
						position: 'absolute',
						left: position === 'left' ? 0 : undefined,
						right: position === 'right' ? 0 : undefined,
						top: '50%',
						transform:
							position === 'left'
								? 'translate(-50%, -50%) rotate(-90deg)'
								: 'translate(50%, -50%) rotate(-90deg)',
						pointerEvents: 'none',
						fontSize: 12,
						padding: '5px 10px',
						backgroundColor: color,
						borderRadius: 10,
						color: '#FFFFFF',
						whiteSpace: 'nowrap',
						zIndex: 100,
					}}
				>
					{__('Drag here')}
				</div>
			)}
		</>
	);
};

import { DetailsBlock } from '../../../components/home/DetailsBlock';
import { useDocTitle } from '../../../../../shared/hooks/useDocTitle';
import { useEvent } from '../../../hooks/useEvent';
import { useTranslate } from '../../../../../shared/hooks/useTranslate';
import { useAbsolutePath } from '../../../hooks/useAbsolutePath';
import { Navigate, useSearchParams } from 'react-router-dom';
import { ContactOrganizerBlock } from '../../../components/home/OrganizerBlock';
import { useAuth } from '../../../hooks/useAuth';
import { RegisterForm } from '../../../components/login/RegisterForm';
import { LoginForm } from '../../../components/login/LoginForm';

export default function Login() {
	const event = useEvent();
	const { __ } = useTranslate();

	useDocTitle(__('Login / Registration'));

	const { isLoggedIn } = useAuth();
	const { prefix } = useAbsolutePath();

	const [searchParams] = useSearchParams();
	const state = searchParams.get('state');
	const isTokenExpired = state === 'expired-token';

	if (isLoggedIn) {
		return <Navigate to={`${prefix}/member#start`} replace />;
	}

	return (
		<>
			{event.params.redirectToRegistration && <DetailsBlock />}

			<section>
				<div className="container section-container">
					{event.params.openInscriptions && !isTokenExpired && <RegisterForm />}
					<LoginForm isTokenExpired={isTokenExpired} />
				</div>
			</section>

			{event.params.redirectToRegistration && <ContactOrganizerBlock />}
		</>
	);
}

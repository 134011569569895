import { Link } from 'react-router-dom';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { useAbsolutePath } from '../../hooks/useAbsolutePath';
import { useAuth } from '../../hooks/useAuth';
import Dropdown, { DropdownMenu } from '../../../../shared/components/Dropdown';
import { useDesign } from '../../hooks/useDesign';
import ConfirmActionModal from '../ConfirmActionModal';
import { useIsCustomizePreview } from '../../hooks/useIsCustomizePreview';

const AdminNavbar = () => {
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();

	const { isAdmin, isTest, user } = useAuth();

	const { isDesignMode, setIsDesignMode } = useDesign();

	const isCustomizePreview = useIsCustomizePreview();
	if (!(isAdmin || isTest) || isCustomizePreview) {
		return null;
	}

	return (
		<div id="event-admin-navbar" className="admin-navbar">
			<div className="dropdown pull-left">
				<ConfirmActionModal
					confirmBtnAction={(closeModal) => {
						setIsDesignMode(false);
						closeModal();
					}}
					content={__(
						'All your unsaved changes will be lost, and this is irreversible.'
					)}
					extraClassName="admin-ui"
				>
					{(openModal) => {
						return (
							<Dropdown
								render={
									<DropdownMenu>
										<li className="dropdown-header">
											{__(
												'In this mode, you can try the registration form as a guest.'
											)}
											<br />
											{__(
												'You will receive notification emails on the email address of this profile.'
											)}
										</li>
										{isAdmin && (
											<li>
												<Link
													to={`${prefix}/admin/users/edit/${user.id}`}
													target="_blank"
													reloadDocument
												>
													{__('Edit the profile of the logged in guest')}
												</Link>
											</li>
										)}
										{isAdmin && (
											<li>
												<Link
													to={`${prefix}/member/deletetestguest`}
													reloadDocument
												>
													{__('Reset logged in guest')}
												</Link>
											</li>
										)}
										{isAdmin && (
											<li>
												{!isDesignMode ? (
													<a
														role="button"
														onClick={() => {
															setIsDesignMode(true);
														}}
													>
														{__('Enable editor')}
													</a>
												) : (
													<a role="button" onClick={openModal}>
														{__('Disable editor')}
													</a>
												)}
											</li>
										)}
									</DropdownMenu>
								}
							>
								<button
									type="button"
									className="btn btn-sm dropdown-toggle text-capitalize"
								>
									<i className="fa fa-info-circle" aria-hidden="true"></i>{' '}
									<span className="hidden-xs">
										{__('You see the event as a logged in guest')}
									</span>{' '}
									<span className="caret"></span>
								</button>
							</Dropdown>
						);
					}}
				</ConfirmActionModal>
			</div>

			<div className="dropdown pull-right">
				<Dropdown
					render={
						<DropdownMenu>
							<li>
								<Link
									to={`${prefix}/admin/events/edit`}
									target="_blank"
									reloadDocument
								>
									{__('Administration space')}
								</Link>
							</li>
						</DropdownMenu>
					}
				>
					<button
						type="button"
						className="btn btn-sm dropdown-toggle text-capitalize"
					>
						<i className="fa fa-user-circle-o fa-fw" aria-hidden="true"></i>{' '}
						<span className="hidden-xs text-capitalize">
							{user.firstname} {user.lastname}
						</span>
					</button>
				</Dropdown>
			</div>
		</div>
	);
};

export default AdminNavbar;

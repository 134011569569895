import ReCAPTCHA from 'react-google-recaptcha';
import FormGroup from '../../../../shared/components/FormGroup';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { FormEvent, createRef, useState } from 'react';
import Alert from '../Alert';
import { useMutation } from '@tanstack/react-query';
import { getServerErrorMessage } from '../../../../shared/utils/errors';
import axios from 'axios';
import { useAbsolutePath } from '../../hooks/useAbsolutePath';
import { useNavigate } from 'react-router-dom';
import { SubmitButton } from '../SubmitButton';

interface Props {
	isTokenExpired?: boolean;
}

export const LoginForm = ({ isTokenExpired }: Props) => {
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();
	const navigate = useNavigate();

	const recaptchaRef = createRef<ReCAPTCHA>();

	const [error, setError] = useState<string | null>(null);

	const resetError = () => {
		setError(null);
	};

	const [mail, setMail] = useState<string>('');

	const sendLoginFormMutation = useMutation({
		mutationFn: async (formData: { mail: string }) => {
			await axios.post(`${prefix}/users/loginJson`, formData);
		},
		onSuccess: () => {
			navigate(`${prefix}/sending-token?mail=${mail}`);
		},
		onError: (err) => {
			setError(getServerErrorMessage(err, err.message));
		},
		onSettled: () => {
			recaptchaRef.current?.reset();
		},
	});

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();

		resetError();

		sendLoginFormMutation.mutate({ mail });
	};

	return (
		<form className="form" onSubmit={handleSubmit}>
			<h2 id="start-login" className="form-title">
				{!isTokenExpired
					? __('I am already registered ?')
					: __('Ask for a new login link')}
			</h2>

			{error && (
				<Alert type="danger" dismissible>
					{error}
				</Alert>
			)}

			<FormGroup label={__('Email')} name="mail" required>
				<div className="form-col">
					<input
						name="mail"
						type="email"
						className="form-control"
						placeholder={__('Enter your email address')}
						value={mail}
						onChange={(e) => {
							setMail(e.target.value);
						}}
					/>
				</div>
			</FormGroup>

			<div className="row">
				<div className="col-sm-12">
					<div className="form-inline text-right">
						<div className="form-group form-action">
							<SubmitButton loading={sendLoginFormMutation.isPending}>
								{__('Receive my connection link')}
							</SubmitButton>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

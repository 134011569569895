import { useState, cloneElement, Fragment } from 'react';
import {
	FloatingPortal,
	offset,
	Placement,
	shift,
	useClick,
	useDismiss,
	useFloating,
	useHover,
	useInteractions,
} from '@floating-ui/react';

export const PopoverContainer = ({
	children,
}: {
	children?: React.ReactNode;
}) => {
	return (
		<div
			className="popover"
			role="tooltip"
			style={{ display: 'block', position: 'initial' }}
		>
			{children}
		</div>
	);
};

export const PopoverTitle = ({ children }: { children?: React.ReactNode }) => {
	return <h3 className="popover-title">{children}</h3>;
};

export const PopoverBody = ({
	children,
	...props
}: JSX.IntrinsicElements['div']) => {
	return (
		<div className="popover-content" {...props}>
			{children}
		</div>
	);
};

interface Props {
	placement?: Placement;
	trigger?: 'click' | 'hover';
	offset?: number;
	portal?: boolean;
	render: (data: { close: () => void; update: () => void }) => React.ReactNode;
	children: JSX.Element;
}

const Popover = ({
	placement,
	trigger = 'click',
	offset: offSetValue = 12,
	portal = true,
	render,
	children,
}: Props) => {
	const [isOpen, setIsOpen] = useState(false);

	const { x, y, refs, strategy, context, update } = useFloating({
		open: isOpen,
		onOpenChange: setIsOpen,
		placement,
		middleware: [offset(offSetValue), shift({ padding: 5 })],
	});
	const { getReferenceProps, getFloatingProps } = useInteractions([
		useClick(context, { enabled: trigger === 'click' }),
		useHover(context, { enabled: trigger === 'hover' }),
		useDismiss(context),
	]);

	const Portal = portal ? FloatingPortal : Fragment;

	return (
		<>
			{cloneElement(
				children,
				getReferenceProps({ ref: refs.setReference, ...children.props })
			)}
			<Portal>
				{isOpen && (
					<div
						ref={refs.setFloating}
						style={{
							position: strategy,
							top: y ?? 0,
							left: x ?? 0,
							zIndex: 1000,
						}}
						{...getFloatingProps()}
					>
						{render({
							close: () => {
								setIsOpen(false);
							},
							update: update,
						})}
					</div>
				)}
			</Portal>
		</>
	);
};

export default Popover;

import axios, { AxiosInstance, AxiosPromise } from 'axios';
import { DeepPartial } from './admin/react/types/deepPartial';
import {
	Account,
	Customfield,
	Document,
	ErrorMail,
	Event,
	Font,
	Partner,
	Speaker,
} from '@invityou/types';
import { LanguageShort } from './i18n';
import { FormToServer } from './admin/react/types/magicform';
import { Program } from '@invityou/types/src/api/Programs';
import { ConfirmOrderForm } from './admin/react/types/users';

export const noCacheHeaders = {
	'Cache-Control': 'no-cache',
	Pragma: 'no-cache',
	Expires: '0',
};

interface InvoicesExport {
	url: string;
}

interface ApiParams {
	token?: string;
	event?: string;
	account?: string;
	lang?: string;
}

export interface FetchUsersQueryParams {
	search?: string;
	page?: number;
	limit?: number;
	sort?: string;
	expand?: string;
	filter?: string;
	invit?: string;
	invitSelectable?: '1' | '0' | 'all';
}

export interface PaginatedAndSearchableFetchParams {
	search?: string;
	page?: number;
	limit?: number;
	sort?: string;
}

type EventExpandParam =
	| 'font_title'
	| 'font_subtitle'
	| 'font_text'
	| 'banner'
	| 'logo'
	| 'organizer'
	| 'favicon';

class Api {
	token: string | null;
	account: string | null;
	event: string | null;
	lang: string | null;
	request: AxiosInstance;

	constructor(params: ApiParams) {
		this.token = params.token || null;
		this.event = params.event || null;
		this.account = params.account || null;
		this.lang = params.lang || null;

		const config: { headers: Record<string, string> } = {
			headers: {},
		};

		if (this.token) {
			config.headers['authorization'] = this.token;
		} else {
			// Use token from body by default
			const token = document.body.dataset.apitoken;

			if (token && token !== '') {
				config.headers['authorization'] = token;
			}
		}

		if (this.lang) {
			config.headers['accept-language'] = this.lang;
		}

		this.request = axios.create(config);
	}

	/**
	 * Tickets
	 */

	fetchTickets(query: { expand: string } | null = null) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/tickets`,
			{
				params: query,
			}
		);
	}

	fetchTicket(id: string) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/tickets/${id}`
		);
	}

	createTicket(data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/tickets`,
			data
		);
	}

	editTicket(id: string, data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/tickets/${id}`,
			data
		);
	}

	deleteTicket(id: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/tickets/${id}`
		);
	}

	/**
	 * Discounts
	 */

	fetchDiscounts() {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/discounts`
		);
	}

	fetchDiscount(id: string) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/discounts/${id}`
		);
	}

	createDiscount(data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/discounts`,
			data
		);
	}

	deleteDiscount(id: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/discounts/${id}`
		);
	}

	/**
	 * Coupons
	 */

	fetchCoupons() {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/coupons`
		);
	}

	fetchCoupon(id: string) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/coupons/${id}`
		);
	}

	createCoupon(data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/coupons`,
			data
		);
	}

	editCoupon(id: string, data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/coupons/${id}`,
			data
		);
	}

	deleteCoupon(id: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/coupons/${id}`
		);
	}

	/**
	 * Orders
	 */

	fetchOrders(
		query: Partial<{
			mode: 'live' | 'test';
			expand: string;
			status: string;
			ticket: string | null;
		}>
	) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/orders`,
			{
				params: query,
			}
		);
	}

	fetchOrder(id: string) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/orders/${id}`
		);
	}

	createOrder(data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/orders`,
			data
		);
	}

	updateOrder(id: string, data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/orders/${id}`,
			data
		);
	}

	payOrder(
		id: string,
		paymentMethodId: string | null,
		name: string,
		address: string,
		zip: string,
		city: string,
		siret: string,
		paymentIntentId: string | null
	) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/orders/${id}/pay`,
			{
				payment_method_id: paymentMethodId,
				name,
				address,
				zip,
				city,
				siret,
				payment_intent_id: paymentIntentId,
			}
		);
	}

	confirmOrder(
		id: string,
		name: string,
		address: string,
		zip: string,
		city: string,
		siret: string
	) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/orders/${id}/confirm`,
			{
				name,
				address,
				zip,
				city,
				siret,
			}
		);
	}

	awaitOrder(id: string, formValues: ConfirmOrderForm) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/orders/${id}/await`,
			formValues
		);
	}

	refundOrder(id: string, items?: string[]) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/orders/${id}/refund`,
			items !== undefined ? { items } : undefined
		);
	}

	generateInvoicesExport(): AxiosPromise<InvoicesExport> {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/orders/export`
		);
	}

	getBillingaddressFromOrder($id: string): AxiosPromise<
		| {
				name: string;
				address: string;
				zip: string;
				city: string;
				siret: string;
		  }
		| undefined
	> {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/orders/${$id}/billingaddress`
		);
	}

	/**
	 * Users
	 */
	fetchUser(id: string, query?: Partial<{ expand: string }>) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/users/${id}`,
			{ params: query }
		);
	}

	fetchUsers(params?: FetchUsersQueryParams) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/users`,
			{ params }
		);
	}

	createUser(data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/users`,
			data
		);
	}

	updateUser(id: string, data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/users/${id}`,
			data
		);
	}

	updateUserCustomfield(data: {
		ids: string[];
		customfieldId: string;
		value: string;
		checked?: boolean;
	}) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/users/customfields`,
			data
		);
	}

	deleteUser(id: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/users/${id}`
		);
	}

	deleteUsers(ids: string[]) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/deleteusers`,
			{ ids }
		);
	}

	fetchPurchasedTickets(id: string, options: { expand: string }) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/users/${id}/tickets`,
			{
				params: options,
			}
		);
	}

	fetchUserOrders(id: string) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/users/${id}/orders`
		);
	}

	/**
	 * Organizers
	 */

	fetchOrganizer() {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/organizers`
		);
	}

	editOrganizer(data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/organizers`,
			data
		);
	}

	/**
	 * Params
	 */

	fetchParams() {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/ticketingparams`
		);
	}

	editParams(data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/ticketingparams`,
			data
		);
	}

	/**
	 * Categories
	 */

	fetchCategory(id: string) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/categories/${id}`
		);
	}

	fetchCategories() {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/categories`
		);
	}

	createCategory(data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/categories`,
			data
		);
	}

	updateCategory(id: string, data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/categories/${id}`,
			data
		);
	}

	deleteCategory(id: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/categories/${id}`
		);
	}

	updateCategoriesPositions(data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/categories/positions`,
			data
		);
	}

	/**
	 * Administrators
	 */

	fetchAdministrators(
		query: Partial<{
			account: string;
			search: string;
			event: string;
			team: string;
			mail: string;
		}>
	) {
		return this.request.get(`/api/v1/administrators`, {
			params: query,
		});
	}

	fetchAdministrator(adminId: string) {
		return this.request.get(
			`/api/v1/accounts/${this.account}/administrators/${adminId}`
		);
	}

	updateAdministrator(id: string, data: unknown) {
		return this.request.post(`/api/v1/administrators/${id}`, data);
	}
	addAdministrator(data: unknown) {
		return this.request.post(`/api/v1/administrators`, data);
	}

	removeAdministrator(adminId: string) {
		return this.request.delete(`/api/v1/administrators/${adminId}`);
	}

	linkAdministratorToAccount(accountId: string, adminId: string) {
		return this.request.put(
			`/api/v1/accounts/${accountId}/administrators/${adminId}`
		);
	}

	unlinkAdministratorToAccount(accountId: string, adminId: string) {
		return this.request.delete(
			`/api/v1/accounts/${accountId}/administrators/${adminId}`
		);
	}

	fetchAccountsLinkedToAdministrator(adminId: string) {
		return this.request.get(`/api/v1/administrators/${adminId}/accounts`);
	}

	inviteAdministrator(id: string) {
		return this.request.post(
			`/api/v1/accounts/${this.account}/administrators/${id}/invite`
		);
	}

	/**
	 * Teams
	 */

	fetchTeams(
		query?: Partial<{ account: string; search: string; event: string }>
	) {
		return this.request.get(`/api/v1/accounts/${this.account}/teams`, {
			params: query,
		});
	}

	fetchTeam(teamId: string) {
		return this.request.get(`/api/v1/accounts/${this.account}/teams/${teamId}`);
	}

	createTeam(data: unknown) {
		return this.request.post(`/api/v1/accounts/${this.account}/teams`, data);
	}

	updateTeam(id: string, data: unknown) {
		return this.request.post(
			`/api/v1/accounts/${this.account}/teams/${id}`,
			data
		);
	}

	removeTeam(teamId: string) {
		return this.request.delete(
			`/api/v1/accounts/${this.account}/teams/${teamId}`
		);
	}

	addAdministratorToTeam(teamId: string, adminId: string) {
		return this.request.put(
			`/api/v1/accounts/${this.account}/teams/${teamId}/administrators/${adminId}`
		);
	}

	removeAdministratorFromTeam(teamId: string, adminId: string) {
		return this.request.delete(
			`/api/v1/accounts/${this.account}/teams/${teamId}/administrators/${adminId}`
		);
	}

	/**
	 * Fonts
	 */

	fetchFont(id: string) {
		return this.request.get(`/api/v1/accounts/${this.account}/fonts/${id}`);
	}

	fetchFonts(query = {}) {
		return this.request.get(`/api/v1/accounts/${this.account}/fonts`, {
			params: query,
		});
	}

	createFont(data: Partial<Font>) {
		return this.request.post(`/api/v1/accounts/${this.account}/fonts`, data);
	}

	updateFont(id: string, data: Partial<Font>) {
		return this.request.post(
			`/api/v1/accounts/${this.account}/fonts/${id}`,
			data
		);
	}

	removeFont(id: string) {
		return this.request.delete(`/api/v1/accounts/${this.account}/fonts/${id}`);
	}

	uploadWoffFile(file: File): AxiosPromise<{ font_url: string }> {
		const data = new FormData();
		data.append('file', file, file.name);
		return this.request.post(
			`/api/v1/accounts/${this.account}/fonts/upload`,
			data
		);
	}

	/**
	 * Events
	 */
	createEvent(data: DeepPartial<Event>) {
		return this.request.post(`/api/v1/accounts/${this.account}/events`, data);
	}

	updateEvent(slug: string, data: DeepPartial<Event>, lang?: LanguageShort) {
		return this.request.post(
			`/api/v1/accounts/${this.account}/events/${slug}`,
			data,
			{ headers: lang ? { 'Accept-Language': lang } : undefined }
		);
	}

	addAdministratorToEvent(slug: string, adminId: string) {
		return this.request.put(
			`/api/v1/accounts/${this.account}/events/${slug}/administrators/${adminId}`
		);
	}

	removeAdministratorFromEvent(slug: string, adminId: string) {
		return this.request.delete(
			`/api/v1/accounts/${this.account}/events/${slug}/administrators/${adminId}`
		);
	}

	addTeamToEvent(slug: string, teamId: string) {
		return this.request.put(
			`/api/v1/accounts/${this.account}/events/${slug}/teams/${teamId}`
		);
	}

	removeTeamFromEvent(slug: string, teamId: string) {
		return this.request.delete(
			`/api/v1/accounts/${this.account}/events/${slug}/teams/${teamId}`
		);
	}

	fetchEvent(
		slug: string,
		query: Partial<{ expand: EventExpandParam[] }> = {},
		lang?: LanguageShort
	) {
		const params = { ...query, expand: query.expand?.join(',') };

		return this.request.get(`/api/v1/accounts/${this.account}/events/${slug}`, {
			headers: lang
				? { ...noCacheHeaders, 'Accept-Language': lang }
				: noCacheHeaders,
			params,
		});
	}

	fetchEvents(
		query: Partial<{
			limit: number;
			ids: string;
			page: number;
			search: string;
			sort: string;
			status: string;
			expand: EventExpandParam[];
			archived: boolean | 'all';
		}> = {}
	) {
		const params = { ...query, expand: query.expand?.join(',') };
		return this.request.get(`/api/v1/accounts/${this.account}/events/`, {
			params,
		});
	}

	deleteEvent(slug: string) {
		return this.request.delete(
			`/api/v1/accounts/${this.account}/events/${slug}`
		);
	}
	/**
	 * Customfield
	 */
	fetchCustomfields(params = {}) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/customfields`,
			{ params: params }
		);
	}

	fetchCustomfield(id: string) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/customfields/${id}`
		);
	}

	createCustomfield(data: Partial<Customfield>) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/customfields`,
			data
		);
	}

	updateCustomfield(id: string, data: Partial<Customfield>) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/customfields/${id}`,
			data
		);
	}

	deleteCustomfield(id: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/customfields/${id}`
		);
	}

	reorderCustomfield(data: Record<string, number>) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/customfields/reorder`,
			data
		);
	}

	/**
	 * Images
	 */
	fetchImage(id: string) {
		return this.request.get(`/api/v1/accounts/${this.account}/images/${id}`);
	}

	uploadImage(file: File) {
		const data = new FormData();
		data.append('file', file, file.name);
		return this.request.post(`/api/v1/accounts/${this.account}/images`, data);
	}

	/*
	 * Checkins
	 */

	fetchCheckins(params?: Partial<{ type: 'online' | 'offline' }>) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/checkins`,
			{
				params,
			}
		);
	}

	fetchCheckin(checkinId: string) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/checkins/${checkinId}`
		);
	}

	createCheckin(data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/checkins`,
			data
		);
	}

	updateCheckin(checkinId: string, data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/checkins/${checkinId}`,
			data
		);
	}

	deleteCheckin(checkinId: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/checkins/${checkinId}`
		);
	}

	fetchCheckinStats(checkinId: string) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/checkins/${checkinId}/stats`
		);
	}

	/*
	 * Checkin code
	 */

	fetchLastCheckinCode() {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/checkincodes/last`
		);
	}

	createCheckinCode() {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/checkincodes`
		);
	}

	deleteCheckinCode(checkinCodeId: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/checkincodes/${checkinCodeId}`
		);
	}

	fetchAllCheckinUsers() {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/checkincodes/sessions`
		);
	}

	checkUsers(
		checkinId: string,
		data: {
			user_id: string;
			present: boolean;
			timestamp: string;
			nameless_companions: number;
		}[]
	) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/checkins/${checkinId}/check`,
			data
		);
	}

	/**
	 * Ebadges
	 */
	fetchEbadges(query: { event: string }) {
		return this.request.get(`/api/v1/accounts/${this.account}/ebadges`, {
			params: query,
		});
	}

	createEbadge(data: unknown, query?: { regenerate: boolean }) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/ebadges`,
			data,
			{
				params: query,
			}
		);
	}

	updateEbadge(id: string, data: unknown, query?: { regenerate: boolean }) {
		return this.request.post(
			`/api/v1/accounts/${this.account}/${this.event}/ebadges/${id}`,
			data,
			{
				params: query,
			}
		);
	}

	deleteEbadge(id: string) {
		return this.request.delete(
			`/api/v1/accounts/${this.account}/${this.event}/ebadges/${id}`
		);
	}

	fetchEbadgeConditions() {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/ebadges/conditions`
		);
	}

	setEbadgeConditions(data: unknown) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/ebadges/conditions`,
			data
		);
	}

	/**
	 * Forms
	 */

	fetchFormItems(formId: string, userId?: string | null) {
		return this.request.get(
			`/${this.event}/forms/view/${formId}${userId ? `/${userId}` : ''}`,
			{
				headers: {
					Accept: 'application/json',
				},
			}
		);
	}

	async editForm(form: FormToServer) {
		const res = await this.request.post(
			`/api/v1/events/${this.account}/${this.event}/forms`,
			form,
			{
				headers: { Accept: 'application/json' },
			}
		);

		return res;
	}

	async submitForm(
		userId: string,
		items: unknown,
		formId: string,
		mail: string | null
	) {
		const res = await this.request.post(
			`/${this.lang}/${this.event}/forms/sendForm/${userId}`,
			{
				formId,
				items,
				mail,
			},
			{
				headers: { Accept: 'application/json' },
			}
		);

		return res;
	}

	async formUploadFile(file: File, formId: string, userId: string | null) {
		const url = `/${this.event}/forms/uploadFile`;
		const formData = new FormData();
		formData.append('file', file);
		formData.append('formId', formId);
		if (userId) {
			formData.append('userId', userId);
		}

		const config = {
			headers: {
				'content-type': 'multipart/form-data',
			},
		};

		return this.request.post(url, formData, config);
	}

	fetchForm(id: string) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/forms/${id}`
		);
	}

	deleteForm(id: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/forms/${id}`
		);
	}

	fetchForms() {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/forms`
		);
	}

	fetchFormOptions(id: string, lang: LanguageShort) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/forms/${id}/options`,
			{ headers: lang ? { 'Accept-Language': lang } : undefined }
		);
	}
	setFormOptions(
		id: string,
		lang: LanguageShort,
		params: {
			before_register_message: string;
			success_message: string;
			pending_approval_message: string;
			declination_message: string;
			invoice_message: string;
			ebadge_message: string;
		}
	) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/forms/${id}/options`,
			{ params },
			{ headers: lang ? { 'Accept-Language': lang } : undefined }
		);
	}

	setFormTerms(id: string, file?: File | null) {
		let data;
		if (file) {
			data = new FormData();
			data.append('file', file, file.name);
		}
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/forms/${id}/terms`,
			data
		);
	}

	/**
	 * Accounts
	 */

	fetchAccount(accountId: string) {
		return this.request.get(`/api/v1/accounts/${accountId}`);
	}

	updateAccount(accountId: string, data: DeepPartial<Account>) {
		return this.request.post(`/api/v1/accounts/${accountId}`, data);
	}

	/**
	 * Event credits
	 */

	addEventCredits(data: {
		description: string;
		value: number;
		admin_id: string;
		expiry_date: string;
	}) {
		return this.request.post(
			`/api/v1/accounts/${this.account}/eventcredits/add`,
			data
		);
	}

	debitEventCredits(data: {
		description: string;
		value: number;
		admin_id: string;
	}) {
		return this.request.post(
			`/api/v1/accounts/${this.account}/eventcredits/debit`,
			data
		);
	}

	fetchNonSpentOrExpiredEventCredits() {
		return this.request.get(
			`/api/v1/accounts/${this.account}/eventcredits/nonspentorexpired`
		);
	}

	fetchRemainingEventCredits() {
		return this.request.get(
			`/api/v1/accounts/${this.account}/eventcredits/remaining`
		);
	}

	fetchEventCredits(
		query: Partial<{
			limit: number;
			ids: string;
			page: number;
			search: string;
			sort: string;
			event: string;
			status: string;
			expand: string;
			start_date: string | null;
			end_date: string | null;
		}> = {}
	) {
		return this.request.get(`/api/v1/accounts/${this.account}/eventcredits`, {
			params: query,
		});
	}

	/**
	 * Campaign credits
	 */

	fetchCampaignCredits(
		query: Partial<{
			limit: number;
			page: number;
			sort: string;
			expand: string;
		}> = {}
	) {
		return this.request.get(
			`/api/v1/accounts/${this.account}/campaigncredits`,
			{
				params: query,
			}
		);
	}

	fetchRemainingCampaignCredits() {
		return this.request.get(
			`/api/v1/accounts/${this.account}/campaigncredits/remaining`
		);
	}

	addCampaignCredits(data: {
		value: number;
		admin_id: string;
		description: string;
	}) {
		return this.request.post(
			`/api/v1/accounts/${this.account}/campaigncredits/add`,
			data
		);
	}

	debitCampaignCredits(data: {
		value: number;
		admin_id: string;
		description: string;
	}) {
		return this.request.post(
			`/api/v1/accounts/${this.account}/campaigncredits/debit`,
			data
		);
	}

	/**
	 * Invits
	 */

	fetchInvits(params: PaginatedAndSearchableFetchParams) {
		return this.request.get(`/${this.event}/admin/invits/get`, {
			params,
		});
	}
	getInvitStats(id: string) {
		return this.request.get(
			`/${this.event}/admin/invits/getInvitStatisticsJson/${id}`
		);
	}
	getRecipientsStats(params: PaginatedAndSearchableFetchParams, id: string) {
		return this.request.get(
			`/${this.event}/admin/invits/getRecipientsStatsJson/${id}`,
			{ params }
		);
	}

	/**
	 * Messages
	 */

	fetchMessagesUnreadCount() {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/messages/unread`
		);
	}

	fetchMessages({
		params,
	}: {
		params?: Partial<{
			page: number;
			limit: number;
			search: string;
			sort: string;
			read: string;
		}>;
	}) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/messages`,
			{ params }
		);
	}

	fetchMessage(id: string) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/messages/${id}`
		);
	}

	readMessage(id: string) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/messages/${id}/read`
		);
	}

	deleteMessage(id: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/messages/${id}`
		);
	}

	/**
	 * Mail errors
	 */
	fetchErrorMail(id: string) {
		return this.request.get(
			`/api/v1/accounts/${this.account}/errormails/${id}`
		);
	}
	fetchErrorMails(params: PaginatedAndSearchableFetchParams) {
		return this.request.get(`/api/v1/accounts/${this.account}/errormails`, {
			params,
		});
	}
	deleteErrorMail(id: string) {
		return this.request.delete(
			`/api/v1/accounts/${this.account}/errormails/${id}`
		);
	}
	createErrorMail(data: Partial<ErrorMail>) {
		return this.request.post(
			`/api/v1/accounts/${this.account}/errormails`,
			data
		);
	}
	updateErrorMail(id: string, data: Partial<ErrorMail>) {
		return this.request.post(
			`/api/v1/accounts/${this.account}/errormails/${id}`,
			data
		);
	}

	/**
	 * Speakers
	 */

	fetchSpeakers(
		query: Partial<{ expand: string[] }> = {},
		lang?: LanguageShort
	) {
		const params = { ...query, expand: query.expand?.join(',') };
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/speakers`,
			{
				headers: lang ? { 'Accept-Language': lang } : undefined,
				params,
			}
		);
	}
	fetchSpeaker(
		id: string,
		query: Partial<{ expand: string[] }> = {},
		lang?: LanguageShort
	) {
		const params = { ...query, expand: query.expand?.join(',') };
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/speakers/${id}`,
			{
				headers: lang ? { 'Accept-Language': lang } : undefined,
				params,
			}
		);
	}

	createSpeaker(data: Partial<Speaker>, lang?: LanguageShort) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/speakers`,
			data,
			{
				headers: lang ? { 'Accept-Language': lang } : undefined,
			}
		);
	}
	updateSpeaker(id: string, data: Partial<Speaker>, lang?: LanguageShort) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/speakers/${id}`,
			data,
			{
				headers: lang ? { 'Accept-Language': lang } : undefined,
			}
		);
	}
	deleteSpeaker(id: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/speakers/${id}`
		);
	}
	reorderSpeakers(data: Record<string, number>) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/speakers/reorder`,
			data
		);
	}

	/**
	 * Partners
	 */

	fetchPartners(
		query: Partial<{ expand: string[] }> = {},
		lang?: LanguageShort
	) {
		const params = { ...query, expand: query.expand?.join(',') };
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/partners`,
			{
				headers: lang ? { 'Accept-Language': lang } : undefined,
				params,
			}
		);
	}
	fetchPartner(
		id: string,
		query: Partial<{ expand: string[] }> = {},
		lang?: LanguageShort
	) {
		const params = { ...query, expand: query.expand?.join(',') };
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/partners/${id}`,
			{
				headers: lang ? { 'Accept-Language': lang } : undefined,
				params,
			}
		);
	}

	createPartner(data: Partial<Partner>, lang?: LanguageShort) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/partners`,
			data,
			{
				headers: lang ? { 'Accept-Language': lang } : undefined,
			}
		);
	}
	updatePartner(id: string, data: Partial<Partner>, lang?: LanguageShort) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/partners/${id}`,
			data,
			{
				headers: lang ? { 'Accept-Language': lang } : undefined,
			}
		);
	}
	deletePartner(id: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/partners/${id}`
		);
	}
	reorderPartners(data: Record<string, number>) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/partners/reorder`,
			data
		);
	}

	/**
	 * Programs
	 */

	fetchPrograms(lang?: LanguageShort) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/programs`,
			{
				headers: lang ? { 'Accept-Language': lang } : undefined,
			}
		);
	}
	fetchProgram(id: string, lang?: LanguageShort) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/programs/${id}`,
			{
				headers: lang ? { 'Accept-Language': lang } : undefined,
			}
		);
	}

	createProgram(data: Partial<Program>, lang?: LanguageShort) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/programs`,
			data,
			{
				headers: lang ? { 'Accept-Language': lang } : undefined,
			}
		);
	}
	updateProgram(id: string, data: Partial<Program>, lang?: LanguageShort) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/programs/${id}`,
			data,
			{
				headers: lang ? { 'Accept-Language': lang } : undefined,
			}
		);
	}
	deleteProgram(id: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/programs/${id}`
		);
	}
	reorderPrograms(data: Record<string, number>) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/programs/reorder`,
			data
		);
	}

	/**
	 * Documents
	 */

	fetchDocuments() {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/documents`
		);
	}

	deleteDocument(id: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/documents/${id}`
		);
	}

	reorderDocuments(data: Record<string, number>) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/documents/reorder`,
			data
		);
	}

	updateDocument(id: string, data: Partial<Document>) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/documents/${id}`,
			data
		);
	}

	uploadDocument(file: File, position?: number) {
		const data = new FormData();
		data.append('file', file, file.name);

		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/documents`,
			data,
			{
				headers: {
					'x-document-position': position,
				},
			}
		);
	}

	/**
	 * Photos
	 */

	fetchPhotos(params: { page?: number; limit?: number } = {}) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/photos`,
			{
				params,
			}
		);
	}

	deletePhoto(id: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/photos/${id}`
		);
	}

	reorderPhotos(data: Record<string, number>) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/photos/reorder`,
			data
		);
	}

	uploadPhoto(file: File, position?: number) {
		const data = new FormData();
		data.append('file', file, file.name);
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/photos`,
			data,
			{
				headers: {
					'x-photo-position': position,
				},
			}
		);
	}

	createSharedFile(user_id: string) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/sharedfiles`,
			{ user_id: user_id }
		);
	}

	uploadSharedFile(id: string, file: File) {
		const data = new FormData();
		data.append('file', file, file.name);
		return this.request.post(
			`/api/v1/event/${this.account}/${this.event}/sharedfiles/${id}/upload`,
			data
		);
	}

	updateSharedFile(id: string, name: string) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/sharefiles/${id}`,
			{ name: name }
		);
	}

	fetchSharedFile(file_id: string) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/sharedfiles/${file_id}`
		);
	}

	fetchAllSharedFiles(params: {
		search?: string;
		page?: number;
		limit?: number;
		sort?: string;
		user_id: string;
	}) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/sharedfiles`,
			{
				params: params,
			}
		);
	}

	deleteSharedFile(file_id: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/sharedfiles/${file_id}`
		);
	}

	notifySharedFile(user_id: string, sharedFileId: string) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/sharedfiles/${user_id}/notify`,
			{ sharedFileId }
		);
	}

	/**
	 * Statistics
	 */
	getAttendanceStatistics(lang?: LanguageShort) {
		return this.request.get(`/${this.event}/admin/statistics/attendance`, {
			headers: lang ? { 'Accept-Language': lang } : undefined,
		});
	}

	getCheckinStatistics(id?: string) {
		return this.request.get(`/${this.event}/admin/statistics/getCheckin`, {
			params: { id },
		});
	}

	/**
	 * Recommendations
	 */

	submitRecommendation(data: unknown) {
		const res = this.request.post(
			`/${this.event}/recommendations/addRecommendationsJson`,
			data
		);

		return res;
	}
	getRecommendations(guestId: string) {
		const res = this.request.get(
			`/${this.event}/recommendations/getRecommendationsJson/${guestId}`
		);
		return res;
	}
	getAllRecommendations() {
		return this.request.get(
			`/${this.event}/admin/recommendations/getAllRecommendationsJson`
		);
	}
	acceptRecommendation(guestId: string) {
		return this.request.get(
			`/${this.event}/admin/recommendations/acceptRecommendationJson/${guestId}`
		);
	}
	refuseRecommendation(guestId: string) {
		return this.request.get(
			`/${this.event}/admin/recommendations/refuseRecommendationJson/${guestId}`
		);
	}

	/**
	 * Duplicates
	 */
	getDuplicates(params: PaginatedAndSearchableFetchParams) {
		return this.request.get(
			`/${this.event}/admin/duplicates/getDuplicatesJson`,
			{ params }
		);
	}
	getMergeData({ user1, user2 }: { user1: string; user2: string }) {
		return this.request.get(`/${this.event}/admin/duplicates/mergeJson`, {
			params: { user1, user2 },
		});
	}
	ignoreDuplicates(selectedIds: string[]) {
		return this.request.post(`/${this.event}/admin/duplicates/deleteJson`, {
			selectedIds,
		});
	}

	/**
	 * Audit logs
	 */
	fetchAuditLogs(
		params?: Partial<{
			actor: string;
			action: string;
			entity: string;
			related_to: string;
			page: number;
			limit: number;
			exclude: string[];
		}>
	) {
		return this.request.get(`/api/v1/accounts/${this.account}/auditlogs`, {
			params: { ...params, exclude: params?.exclude?.join(',') },
		});
	}

	/**
	 * Custom pages
	 */

	fetchCustomPage(shortId: string) {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/custompages/${shortId}`
		);
	}

	fetchCustomPages() {
		return this.request.get(
			`/api/v1/events/${this.account}/${this.event}/custompages`
		);
	}

	createCustomPage(data: any) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/custompages`,
			data
		);
	}

	updateCustomPage(shortId: string, data: any) {
		return this.request.post(
			`/api/v1/events/${this.account}/${this.event}/custompages/${shortId}`,
			data
		);
	}

	deleteCustomPage(shortId: string) {
		return this.request.delete(
			`/api/v1/events/${this.account}/${this.event}/custompages/${shortId}`
		);
	}

	/**
	 * Imports
	 */
	importCSV(file: File, hasHeaders: boolean) {
		const data = new FormData();
		data.append('file', file, file.name);
		return this.request.post(
			`/${this.event}/admin/imports/uploadCsvJson`,
			data,
			{ headers: { 'x-has-headers': hasHeaders ? 1 : 0 } }
		);
	}
}

export default Api;

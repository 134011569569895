import { useQuery } from '@tanstack/react-query';
import Api from '../../../../api';
import { Ticket } from '../../types/ticketing';
import { useApi } from '../../hooks/useApi';

const fetchTickets = (api: Api) => async () => {
	const response = await api.fetchTickets();
	return response.data as Ticket[];
};

export const useTicketsQuery = () => {
	const api = useApi();
	return useQuery({
		queryKey: ['tickets'],
		queryFn: fetchTickets(api),
	});
};

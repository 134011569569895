import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useDocTitle } from '../../../../shared/hooks/useDocTitle';
import { useParams } from 'react-router-dom';
import { useApi } from '../../hooks/useApi';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { TranslatedValue } from '../../utils/i18n';
import { translateWithDefaultFallback } from '../../utils/i18n';
import { useDesign } from '../../hooks/useDesign';
import type { CustomPage } from '../../hooks/editor/useUpdateCustomPageMutation';
import { BlockEditor } from './BlockEditor';
import { css } from '@emotion/css';

export const CustomPagePage = () => {
	const { lang } = useTranslate();
	const api = useApi();
	const { shortid, slug } = useParams() as { shortid: string; slug: string };
	const queryClient = useQueryClient();

	const customPageQuery = useQuery({
		queryKey: ['custom_pages', shortid],
		queryFn: async () => {
			const { data } = await api.fetchCustomPage(shortid);
			return data as CustomPage;
		},
		initialData: () => {
			return queryClient
				.getQueryData<CustomPage[]>(['custom_pages'])
				?.find((d) => d.short_id === shortid);
		},
		initialDataUpdatedAt: () => {
			return queryClient.getQueryState(['custom_pages'])?.dataUpdatedAt;
		},
		staleTime: 1000,
	});

	const getTranslation = (translatedValue: TranslatedValue) => {
		return translateWithDefaultFallback(translatedValue, lang);
	};

	useDocTitle(
		customPageQuery.data ? getTranslation(customPageQuery.data.title) : slug
	);

	const { isDesignMode } = useDesign();

	return (
		<section
			className={css`
				ul {
					clear: both;
					margin-top: 1em;
					margin-bottom: 1em;
					text-align: left;
					list-style: disc;
					padding-left: 30px;

					li {
						margin-bottom: 10px;

						&:last-child {
							margin-bottom: 0;
						}
					}
				}

				ol {
					text-align: center;
					margin-left: 36px;

					li {
						list-style-type: decimal;
					}
				}
			`}
		>
			{customPageQuery.isSuccess && (
				<div className="container section-container">
					{customPageQuery && (
						<h2 className="section-heading custom-font-title">
							{getTranslation(customPageQuery.data.title)}
						</h2>
					)}

					{!isDesignMode ? (
						customPageQuery.data.blocks.map((block) => {
							let element = null;
							switch (block.element.type) {
								case 'html':
									element = (
										<div
											dangerouslySetInnerHTML={{
												__html: getTranslation(block.element.content),
											}}
										></div>
									);
							}

							return <div key={block.id}>{element}</div>;
						})
					) : (
						<BlockEditor customPage={customPageQuery.data} />
					)}
				</div>
			)}
		</section>
	);
};

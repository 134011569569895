import { useDroppable } from '@dnd-kit/core';
import React from 'react';
import { css } from '@emotion/css';
import { useHover } from '../../../../admin/react/hooks/campaigns/useHover';
import { Draggable } from '../DragHandle';
import { DropHighlight } from '../DropHighlight';

export const SortableItem = ({
	id,
	index,
	disabled = false,
	children,
	active,
}: {
	id: string;
	index: number;
	disabled?: boolean;
	children: React.ReactNode;
	active: { id: string; index: number } | null;
}) => {
	const [hoverRef, isHovered] = useHover<HTMLDivElement>();
	const { setNodeRef, isOver } = useDroppable({
		id,
		disabled,
		data: { index },
	});

	return (
		<div ref={hoverRef}>
			<div
				ref={setNodeRef}
				className={css`
					position: relative;

					&:hover::after {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						pointer-events: none;
						border: 2px solid var(--info-color);
					}
				`}
			>
				{isOver && active && (
					<DropHighlight
						position={index - active.index <= 0 ? 'top' : 'bottom'}
						color="var(--info-color)"
					/>
				)}
				{isHovered && !disabled && !active && (
					<Draggable id={id} index={index} />
				)}
				{children}
			</div>
		</div>
	);
};

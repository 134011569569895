import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Fancybox } from '../../../components/photos/FancyBox';
import { useApi } from '../../../hooks/useApi';
import { useDocTitle } from '../../../../../shared/hooks/useDocTitle';
import { useEvent } from '../../../hooks/useEvent';
import { useTranslate } from '../../../../../shared/hooks/useTranslate';
import { Photo } from '@invityou/types';
import { Link, Navigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { useAbsolutePath } from '../../../hooks/useAbsolutePath';
import { usePageQueryParams } from '../../../../../admin/react/hooks/usePageQueryParams';
import { useLimitQueryParams } from '../../../../../admin/react/hooks/users/useLimitQueryParams';
import { getUrlWithParam } from '../../../../../admin/react/hooks/useCustomQueryParams';

const defaultLimit = 20;

export default function Photos() {
	const event = useEvent();
	const { __ } = useTranslate();
	const api = useApi();

	const photosTitle =
		event.template.photos_title && event.template.photos_title !== ''
			? event.template.photos_title
			: __('Photo gallery');

	useDocTitle(`${photosTitle} | ${event.name}`);

	const [page] = usePageQueryParams();
	const [limit] = useLimitQueryParams(defaultLimit);

	const options = { page, limit };

	const photosQuery = useQuery({
		queryKey: ['photos', event.slug, options],
		queryFn: async () => {
			const { headers, data } = await api.fetchPhotos(options);
			return {
				total: Number.parseInt(headers['pagination-count'] ?? '0', 10),
				data: data as Photo[],
			};
		},
		placeholderData: keepPreviousData,
	});

	const { prefix } = useAbsolutePath();
	const { isLoggedIn } = useAuth();

	if (!event.params.isOpenEvent && !isLoggedIn) {
		return <Navigate to={`${prefix}/login#start`} replace />;
	}

	const photos = photosQuery.data?.data ?? [];

	const photoTotal = photosQuery.data?.total ?? defaultLimit;
	const lastPage = Math.ceil(photoTotal / limit);

	return (
		<section id="event-photos">
			<div className="container section-container">
				<h2 className="section-heading custom-font-title">{photosTitle}</h2>

				{photosQuery.isSuccess && (
					<Fancybox>
						<ul className="gallery">
							{photos.map((photo) => {
								return (
									<li key={photo.id}>
										<a href={photo.url} data-fancybox="gallery">
											<img
												src={photo.url}
												loading="lazy"
												className="fo_gallery"
											/>
										</a>
									</li>
								);
							})}
						</ul>
					</Fancybox>
				)}

				<div className="text-center">
					<div className="pagination btn-group">
						{page !== 1 && (
							<Link
								to={getUrlWithParam(location, 'page', '')}
								className={`btn btn-default ${page === 1 ? 'disabled' : ''}`}
								title={__('First page')}
								rel="first"
								reloadDocument
							>
								<i className="fa fa-angle-double-left fa-fw"></i>
							</Link>
						)}
						<Link
							to={getUrlWithParam(location, 'page', (page - 1).toString())}
							className={`btn btn-default ${page === 1 ? 'disabled' : ''}`}
							title={__('Previous page')}
							rel="prev"
							reloadDocument
						>
							<i className="fa fa-angle-left fa-fw"></i>
						</Link>
						<Link
							to={getUrlWithParam(location, 'page', (page + 1).toString())}
							className={`btn btn-default ${page >= lastPage ? 'disabled' : ''}`}
							title={__('Next page')}
							rel="next"
							reloadDocument
						>
							<i className="fa fa-angle-right fa-fw"></i>
						</Link>
					</div>
				</div>
			</div>
		</section>
	);
}

import { useEvent } from '../../hooks/useEvent';
import { useTranslate } from '../../../../shared/hooks/useTranslate';

const WhiteLabelBanner = () => {
	const { __ } = useTranslate();
	const event = useEvent();

	if (event.params.isWhiteLabel) {
		return null;
	}

	return (
		<div className="credits">
			<div className="container">
				<div className="img-credits">
					<a
						href={'https://www.invityou.com'}
						target="_blank"
						rel="noopener noreferrer"
					>
						<img
							src="/img/brand/logo_invityou_grey.svg"
							alt="InvitYou"
							width={124}
							height={30}
						/>
					</a>
				</div>
				<span className="text-credits">
					{__('Event managed by the platform')}{' '}
					<a
						href={'https://www.invityou.com'}
						target="_blank"
						rel="noopener noreferrer"
					>
						InvitYou
					</a>
					.
				</span>
			</div>
		</div>
	);
};

export default WhiteLabelBanner;

import { Link, useLocation, useParams, Location } from 'react-router-dom';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { useAbsolutePath } from '../../hooks/useAbsolutePath';
import { useAuth } from '../../hooks/useAuth';
import { useEvent } from '../../hooks/useEvent';
import Dropdown, { DropdownMenu } from '../../../../shared/components/Dropdown';
import { Image } from '@invityou/types';
import { useLayoutConfig } from '../../hooks/useLayoutConfig';
import { isEventOver } from '../../utils/isEventOver';
import { useEffect, useState } from 'react';
import { translateWithDefaultFallback } from '../../utils/i18n';
import {
	DataRef,
	DndContext,
	DragEndEvent,
	DragOverlay,
	DragStartEvent,
	PointerSensor,
	useDroppable,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { useUpdateEvent } from '../../../../shared/queries/events';
import { useApi } from '../../hooks/useApi';
import { useDesign } from '../../hooks/useDesign';
import { useToast } from '../../hooks/useToast';
import { Draggable, DragHandle } from '../DragHandle';
import { useHover } from '../../../../admin/react/hooks/campaigns/useHover';
import { css } from '@emotion/css';
import { DropHighlight } from '../DropHighlight';
import { useIsCustomizePreview } from '../../hooks/useIsCustomizePreview';

const isLangInUrl = (langInUrl: string | undefined) => {
	return langInUrl !== undefined;
};

const getLanguageUrl = (
	location: Location,
	langInUrl: string | undefined,
	language: string
) => {
	return isLangInUrl(langInUrl)
		? `/${language}${location.pathname.slice(3)}`
		: `/${language}${location.pathname}`;
};

const LanguageTab = () => {
	const { lang: languageSelected } = useTranslate();
	const { lang: langInUrl } = useParams();
	const event = useEvent();
	const location = useLocation();

	const languages = [
		{
			localeCode: 'fr' as const,
			name: 'Français',
		},
		{
			localeCode: 'en' as const,
			name: 'English',
		},
		{
			localeCode: 'es' as const,
			name: 'Spanish',
		},
	];

	const availableLangs = event.params.selected_languages;

	const renderLanguages = languages
		.filter((language) => availableLangs.includes(language.localeCode))
		.filter((language) => language.localeCode !== languageSelected)
		.map((language) => {
			return (
				<li key={language.localeCode}>
					<Link to={getLanguageUrl(location, langInUrl, language.localeCode)}>
						{language.name}
					</Link>
				</li>
			);
		});

	return (
		<div className="main-navbar-btn navbar-btn btn-group">
			<Dropdown render={<DropdownMenu>{renderLanguages}</DropdownMenu>}>
				<button type="button" className="btn dropdown-toggle btn-header">
					{languageSelected} <span className="caret"></span>
				</button>
			</Dropdown>
		</div>
	);
};

const NavItem = ({
	htmlId,
	hide,
	url,
	label,
}: {
	htmlId?: string;
	hide: boolean;
	url: string;
	label: string;
}) => {
	return (
		<li id={htmlId} className={`${hide ? 'hide' : ''}`}>
			<Link
				id={htmlId ? `${htmlId}-link` : undefined}
				to={url}
				className="btn-header"
			>
				{label}
			</Link>
		</li>
	);
};

const SortableNavItem = ({
	id,
	htmlId,
	index,
	disabled = false,
	active,
	url,
	label,
}: {
	id: string;
	htmlId?: string;
	index: number;
	disabled?: boolean;
	active: { id: string; index: number } | null;
	url: string;
	label: string;
}) => {
	const [hoverRef, isHovered] = useHover<HTMLLIElement>();
	const { setNodeRef, isOver } = useDroppable({
		id,
		disabled,
		data: { index },
	});

	return (
		<li ref={hoverRef} id={htmlId} className={`${disabled ? 'hide' : ''}`}>
			<div
				ref={setNodeRef}
				className={`${isHovered ? 'is-hovered' : ''} ${css`
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;

					&.is-hovered::after {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						bottom: 0;
						right: 0;
						pointer-events: none;
						border: 2px solid var(--info-color);
					}
				`}`}
			>
				{isOver && active && (
					<DropHighlight
						position={index - active.index <= 0 ? 'left' : 'right'}
						color="var(--info-color)"
						hideText
					/>
				)}
				{isHovered && !disabled && !active && (
					<Draggable id={id} index={index} />
				)}
			</div>
			<Link
				id={htmlId ? `${htmlId}-link` : undefined}
				to={url}
				className="btn-header"
			>
				{label}
			</Link>
		</li>
	);
};

const Nav = () => {
	const { __, lang } = useTranslate();
	const { prefix } = useAbsolutePath();
	const { isAdmin, user } = useAuth();
	const event = useEvent();
	const api = useApi();
	const { isDesignMode } = useDesign();

	const logo = event.logo as Image | null;

	const layoutConfig = useLayoutConfig();

	const documentsCount = layoutConfig.documentsCount ?? 0;
	const speakersCount = layoutConfig.speakersCount ?? 0;
	const programCount = layoutConfig.programCount ?? 0;
	const partnersCount = layoutConfig.partnersCount ?? 0;
	const photosCount = layoutConfig.photosCount ?? 0;
	const liveCheckinsCount = layoutConfig.liveCheckins?.length ?? 0;
	const customPagesNav = layoutConfig.customPagesNav;

	const [isNavCollapsed, setIsNavCollapsed] = useState(true);

	const toggleSidebar = () => {
		setIsNavCollapsed((isCollapsed) => !isCollapsed);
	};

	const [active, setActive] = useState<{ id: string; index: number } | null>(
		null
	);
	const [navObjectsState, setNavObjectsState] = useState<
		{ id: string; htmlId?: string; hide: boolean; url: string; label: string }[]
	>([]);
	const homeLabel = __('Home');
	useEffect(() => {
		const menu = {
			description:
				event.params.website_enabled &&
				!event.params.redirectToRegistration &&
				event.description !== null &&
				event.description !== '' &&
				event.template.description_block &&
				event.template.menu_description_active &&
				event.template.menu_description_label,
			documents:
				event.params.website_enabled &&
				!event.params.redirectToRegistration &&
				documentsCount > 0 &&
				event.template.documents_block &&
				event.template.menu_documents_active &&
				event.template.menu_documents_label,
			speakers:
				event.params.website_enabled &&
				!event.params.redirectToRegistration &&
				speakersCount > 0 &&
				event.template.speakers_block &&
				event.template.menu_speakers_active &&
				event.template.menu_speakers_label,
			program:
				event.params.website_enabled &&
				!event.params.redirectToRegistration &&
				programCount > 0 &&
				event.template.program_block &&
				event.template.menu_program_active &&
				event.template.menu_program_label,
			partners:
				event.params.website_enabled &&
				!event.params.redirectToRegistration &&
				partnersCount > 0 &&
				event.template.partners_block &&
				event.template.menu_partners_active &&
				event.template.menu_partners_label,
			gallery:
				event.params.website_enabled &&
				!event.params.redirectToRegistration &&
				photosCount > 0 &&
				event.template.menu_galleries_active &&
				event.template.menu_galleries_label,
			map:
				event.params.website_enabled &&
				!event.params.redirectToRegistration &&
				event.lat &&
				event.lon &&
				event.template.location_block &&
				event.template.menu_localisation_active &&
				event.template.menu_localisation_label,
			organizer:
				event.email_organizer &&
				event.template.organizer_block &&
				event.template.menu_organizer_active &&
				event.template.menu_organizer_label,
			participants:
				event.params.website_enabled &&
				!event.params.redirectToRegistration &&
				event.template.participants_block &&
				event.template.menu_participants_active &&
				event.template.menu_participants_label,
			live: liveCheckinsCount > 0 && event.template.menu_live_label,
		};

		const defaultNavItems = [
			'home',
			'description',
			'documents',
			'speakers',
			'program',
			'partners',
			'gallery',
			'map',
			'contact',
			'participants',
			'live',
			...customPagesNav.map((page) => page.short_id),
		];

		const eventNavItems = event.template.nav_items ?? [];
		const navItems = [...eventNavItems].filter((navItem) =>
			defaultNavItems.includes(navItem)
		);

		for (const defaultNavItem of defaultNavItems) {
			if (!navItems.includes(defaultNavItem)) {
				navItems.push(defaultNavItem);
			}
		}

		const toNavObject = (navItem: string) => {
			switch (navItem) {
				case 'home': {
					return { id: navItem, hide: false, url: prefix, label: homeLabel };
				}
				case 'description': {
					return {
						id: navItem,
						htmlId: 'nav-description',
						hide: !menu.description,
						url: `${prefix}#event-description`,
						label: event.template.menu_description_label,
					};
				}
				case 'documents': {
					return {
						id: navItem,
						htmlId: 'nav-documents',
						hide: !menu.documents,
						url: `${prefix}/documents#start`,
						label: event.template.menu_documents_label,
					};
				}
				case 'speakers': {
					return {
						id: navItem,
						htmlId: 'nav-speakers',
						hide: !menu.speakers,
						url: `${prefix}#event-speakers`,
						label: event.template.menu_speakers_label,
					};
				}
				case 'program': {
					return {
						id: navItem,
						htmlId: 'nav-program',
						hide: !menu.program,
						url: `${prefix}#event-program`,
						label: event.template.menu_program_label,
					};
				}
				case 'partners': {
					return {
						id: navItem,
						htmlId: 'nav-partners',
						hide: !menu.partners,
						url: `${prefix}#event-partners`,
						label: event.template.menu_partners_label,
					};
				}
				case 'gallery': {
					return {
						id: navItem,
						htmlId: 'nav-photos',
						hide: !menu.gallery,
						url: `${prefix}/photos#start`,
						label: event.template.menu_galleries_label,
					};
				}
				case 'map': {
					return {
						id: navItem,
						htmlId: 'nav-location',
						hide: !menu.map,
						url: `${prefix}#event-location`,
						label: event.template.menu_localisation_label,
					};
				}
				case 'contact': {
					return {
						id: navItem,
						htmlId: 'nav-organizer',
						hide: !menu.organizer || isEventOver(event.end_date),
						url: `${prefix}/contact#start`,
						label: event.template.menu_organizer_label,
					};
				}
				case 'participants': {
					return {
						id: navItem,
						htmlId: 'nav-participants',
						hide: !menu.participants,
						url: `${prefix}/participants#start`,
						label: event.template.menu_participants_label,
					};
				}
				case 'live': {
					return {
						id: navItem,
						htmlId: 'nav-live',
						hide: !menu.live,
						url: `${prefix}/checkins#start`,
						label: event.template.menu_live_label,
					};
				}
				default: {
					const nav = customPagesNav.find((page) => page.short_id === navItem);

					if (nav) {
						return {
							id: navItem,
							hide: false,
							url: `${prefix}/pages/${nav.short_id}/${nav.slug}`,
							label: translateWithDefaultFallback(nav.nav_title, lang),
						};
					}
				}
			}

			return {
				id: navItem,
				hide: false,
				url: '',
				label: '',
			};
		};

		let navObjects = navItems.map((navItem) => toNavObject(navItem));

		if (isDesignMode) {
			navObjects = navObjects.filter((navObject) => !navObject.hide);
		}

		setNavObjectsState(navObjects);
	}, [
		event,
		documentsCount,
		speakersCount,
		programCount,
		partnersCount,
		photosCount,
		liveCheckinsCount,
		customPagesNav,
		isDesignMode,
		prefix,
		lang,
		homeLabel,
	]);

	const updateEventMutation = useUpdateEvent(api, event.slug);

	const sensors = useSensors(
		useSensor(PointerSensor, { activationConstraint: { distance: 10 } })
	);

	const handleDragStart = (event: DragStartEvent) => {
		const { active } = event;
		const data = active.data as DataRef<{ index: number }>;

		setActive({
			id: active.id as string,
			index: data.current?.index ?? 0,
		});
	};

	const toast = useToast();
	const handleDragEnd = (event: DragEndEvent) => {
		setActive(null);

		const { active, over } = event;

		if (!over) {
			return;
		}

		if (active.id !== over.id) {
			setNavObjectsState((navObjects) => {
				const idArray = navObjects.map((navObject) => navObject.id);
				const oldIndex = idArray.indexOf(active.id as string);
				const newIndex = idArray.indexOf(over.id as string);

				const orderedNavObjects = arrayMove(navObjects, oldIndex, newIndex);

				updateEventMutation.mutate(
					{
						template: { nav_items: orderedNavObjects.map((nav) => nav.id) },
					},
					{
						onSuccess: () => {
							toast(__('Order updated.'), 'success');
						},
					}
				);

				return orderedNavObjects;
			});
		}
	};

	const isCustomizePreview = useIsCustomizePreview();

	return (
		<nav
			id="event-navigation"
			className={`
				main-navbar navbar custom-font-content
				navigation-logo-${event.template.navigation_logo_alignement}
				${event.template.navigation_fixed ? 'navigation-fixed' : ''}
				${event.template.navigation_transparent ? 'navigation-transparent' : ''}
			`}
			style={{
				pointerEvents: isCustomizePreview ? 'none' : undefined,
			}}
		>
			<div className="main-navbar-container container-fluid">
				<div className="main-navbar-header navbar-header">
					<Link to={`${prefix}`} className="main-navbar-brand navbar-brand">
						<img
							src={logo ? logo.url : undefined}
							className="fo_head_logo"
							id="event-logo"
						/>
					</Link>
				</div>

				<div id="main-navbar-menu" className="main-navbar-menu">
					<div className="main-navbar-toggle main-navbar-btn navbar-btn navbar-toggle">
						<button
							type="button"
							className="btn btn-header"
							onClick={() => {
								toggleSidebar();
							}}
						>
							<i className="fa fa-bars fa-fw" aria-hidden="true" />
						</button>
					</div>
					{user ? (
						<div className="main-navbar-btn navbar-btn btn-group">
							<Dropdown
								render={
									<DropdownMenu>
										<li className="dropdown-header">{user.firstname}</li>

										{user.status !== null ? (
											<li>
												<Link to={`${prefix}/member#start`}>
													{__('Guest area')}
												</Link>
											</li>
										) : user.status === null && event.openReply ? (
											<li>
												<Link to={`${prefix}/register#start`}>
													{event.template.header_btn_text}
												</Link>
											</li>
										) : null}

										{!isAdmin && (
											<>
												<li role="separator" className="divider"></li>
												<li>
													<Link to={`${prefix}/logout`} reloadDocument>
														{__('Log Out')}
													</Link>
												</li>
											</>
										)}
									</DropdownMenu>
								}
							>
								<button
									type="button"
									className="btn btn-primary dropdown-toggle"
								>
									<i className="fa fa-user fa-fw" aria-hidden="true"></i>
									<span className="btn-text">{user.firstname}</span>
								</button>
							</Dropdown>
						</div>
					) : event.inscription_form_id !== null || event.tickets_on_sale ? (
						<Link
							to={`${prefix}/login#start`}
							className="main-navbar-btn navbar-btn btn btn-secondary"
						>
							{__('Sign in / Sign up')}
						</Link>
					) : null}

					{event.params.multilang_enabled &&
						event.params.selected_languages.length > 1 && <LanguageTab />}

					<div
						id="main-navbar-collapse"
						className={`main-navbar-collapse ${isNavCollapsed ? '' : 'open'}`}
					>
						<ul className="main-navbar-nav navbar-nav nav">
							{isDesignMode ? (
								<DndContext
									onDragStart={handleDragStart}
									onDragEnd={handleDragEnd}
									sensors={sensors}
								>
									{navObjectsState.map((navObject, index) => {
										return (
											<SortableNavItem
												key={navObject.id}
												id={navObject.id}
												index={index}
												disabled={navObject.hide}
												active={active}
												htmlId={navObject.htmlId}
												url={navObject.url}
												label={navObject.label}
											/>
										);
									})}

									<DragOverlay dropAnimation={null}>
										{active ? <DragHandle /> : null}
									</DragOverlay>
								</DndContext>
							) : (
								navObjectsState.map(({ id, htmlId, hide, url, label }) => {
									return (
										<NavItem
											key={id}
											htmlId={htmlId}
											hide={hide}
											url={url}
											label={label}
										/>
									);
								})
							)}
						</ul>

						<button
							type="button"
							className="main-navbar-close main-navbar-btn btn btn-header"
							onClick={() => {
								toggleSidebar();
							}}
						>
							<i className="fa fa-times fa-fw" aria-hidden="true"></i>
						</button>
					</div>
				</div>
			</div>
		</nav>
	);
};

export default Nav;

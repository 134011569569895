import { Link } from 'react-router-dom';
import { useEvent } from '../../hooks/useEvent';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { useAbsolutePath } from '../../hooks/useAbsolutePath';
import { Image } from '@invityou/types';

const SocialNetworks = () => {
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();
	const event = useEvent();

	const logo = event.logo as Image | null;

	return (
		<div className="social-network">
			<div className="container">
				<div className="social-footer">
					<ul>
						{event.facebook_url !== null && event.facebook_url !== '' && (
							<li>
								<a
									href={event.facebook_url}
									className="btn btn-secondary btn-circle"
									target="_blank"
									rel="noopener noreferrer"
								>
									<i className="fa fa-facebook" aria-hidden="true"></i>
								</a>
							</li>
						)}
						{event.twitter_url !== null && event.twitter_url !== '' && (
							<li>
								<a
									href={event.twitter_url}
									className="btn btn-secondary btn-circle"
									target="_blank"
									rel="noopener noreferrer"
								>
									<i className="fa fa-twitter" aria-hidden="true"></i>
								</a>
							</li>
						)}
						{event.linkedin_url !== null && event.linkedin_url !== '' && (
							<li>
								<a
									href={event.linkedin_url}
									className="btn btn-secondary btn-circle"
									target="_blank"
									rel="noopener noreferrer"
								>
									<i className="fa fa-linkedin" aria-hidden="true"></i>
								</a>
							</li>
						)}
						{event.youtube_url !== null && event.youtube_url !== '' && (
							<li>
								<a
									href={event.youtube_url}
									className="btn btn-secondary btn-circle"
									target="_blank"
									rel="noopener noreferrer"
								>
									<i className="fa fa-youtube-play" aria-hidden="true"></i>
								</a>
							</li>
						)}
						{event.instagram_url !== null && event.instagram_url !== '' && (
							<li>
								<a
									href={event.instagram_url}
									className="btn btn-secondary btn-circle"
									target="_blank"
									rel="noopener noreferrer"
								>
									<i className="fa fa-instagram" aria-hidden="true"></i>
								</a>
							</li>
						)}
					</ul>
				</div>

				<div className="logo-footer">
					{logo && (
						<Link to={prefix}>
							<img src={logo.url} className="fo_foot_logo" />
						</Link>
					)}

					<ul>
						<li>
							<Link to={`${prefix}/legals`}>{__('Legal Mentions')}</Link>
						</li>
					</ul>
				</div>
			</div>
		</div>
	);
};

export default SocialNetworks;

import { useQuery } from '@tanstack/react-query';
import { DescriptionBlock } from '../../../components/home/DescriptionBlock';
import { DetailsBlock } from '../../../components/home/DetailsBlock';
import { DocumentBlock } from '../../../components/home/DocumentBlock';
import { GalleryBlock } from '../../../components/home/GalleryBlock';
import { LocationBlock } from '../../../components/home/LocationBlock';
import { ContactOrganizerBlock } from '../../../components/home/OrganizerBlock';
import { PartnersBlock } from '../../../components/home/PartnersBlock';
import { ProgramBlock } from '../../../components/home/ProgramBlock';
import { SpeakersBlock } from '../../../components/home/SpeakersBlock';
import { useDocTitle } from '../../../../../shared/hooks/useDocTitle';
import { useEvent } from '../../../hooks/useEvent';
import { useAbsolutePath } from '../../../hooks/useAbsolutePath';
import axios from 'axios';
import { Partner, Photo, Speaker } from '@invityou/types';
import { Program } from '@invityou/types/src/api/Programs';
import { useLayoutConfig } from '../../../hooks/useLayoutConfig';
import { Navigate } from 'react-router-dom';
import Header from '../../../components/layout/Header';
import { useAuth } from '../../../hooks/useAuth';
import {
	DataRef,
	DndContext,
	DragEndEvent,
	DragOverlay,
	DragStartEvent,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTranslate } from '../../../../../shared/hooks/useTranslate';
import { useDesign } from '../../../hooks/useDesign';
import { useApi } from '../../../hooks/useApi';
import { useUpdateEvent } from '../../../../../shared/queries/events';
import { useToast } from '../../../hooks/useToast';
import { getServerErrorMessage } from '../../../../../shared/utils/errors';
import { DragHandle } from '../../../components/DragHandle';
import { EventBlock } from '@invityou/types/bin/api/Events';
import { SortableItem } from '../../../components/editor/SortableItem';

const useShowQuery = () => {
	const { prefix } = useAbsolutePath();

	return useQuery({
		queryKey: ['event_show', prefix],
		queryFn: async () => {
			const { data } = await axios.get(`${prefix}/events/showJson`);
			return data as {
				speakers: Speaker[];
				program: Program[];
				partners: Partner[];
				photos: Photo[];
			};
		},
		retry: false,
	});
};

const defaultBlocks: EventBlock[] = [
	{ id: uuidv4(), element: { type: 'event_description' } },
	{ id: uuidv4(), element: { type: 'documents' } },
	{ id: uuidv4(), element: { type: 'speakers' } },
	{ id: uuidv4(), element: { type: 'program' } },
	{ id: uuidv4(), element: { type: 'partners' } },
	{ id: uuidv4(), element: { type: 'gallery' } },
	{ id: uuidv4(), element: { type: 'location' } },
	{ id: uuidv4(), element: { type: 'contact' } },
];

export default function Show() {
	const event = useEvent();
	const api = useApi();
	const { __ } = useTranslate();
	const toast = useToast();
	const { documentsCount } = useLayoutConfig();
	const showQuery = useShowQuery();
	const { prefix } = useAbsolutePath();
	const { isLoggedIn } = useAuth();

	useDocTitle(event.name);

	const { isDesignMode } = useDesign();

	const updateEventMutation = useUpdateEvent(api, event.slug);

	const [blocks, setBlocks] = useState<EventBlock[]>(
		event.template.blocks ?? defaultBlocks
	);

	const [active, setActive] = useState<{ id: string; index: number } | null>(
		null
	);

	const sensors = useSensors(
		useSensor(PointerSensor, { activationConstraint: { distance: 10 } })
	);

	const handleDragStart = (event: DragStartEvent) => {
		const { active } = event;
		const data = active.data as DataRef<{ index: number }>;

		setActive({
			id: active.id as string,
			index: data.current?.index ?? 0,
		});
	};

	const handleDragEnd = (event: DragEndEvent) => {
		setActive(null);

		const { active, over } = event;

		if (!over) {
			return;
		}

		if (active.id !== over.id) {
			const idArray = blocks.map((block) => block.id);
			const oldIndex = idArray.indexOf(active.id as string);
			const newIndex = idArray.indexOf(over.id as string);

			const orderedBlocks = arrayMove(blocks, oldIndex, newIndex);

			setBlocks(orderedBlocks);
			updateEventMutation.mutate(
				{ template: { blocks: orderedBlocks } },
				{
					onSuccess: () => {
						toast(__('Order updated.'), 'success');
					},
					onError: (err) => {
						toast(
							getServerErrorMessage(err, __('An error has occured.')),
							'danger'
						);
					},
				}
			);
		}
	};

	if (event.params.redirectToRegistration || !event.params.website_enabled) {
		return <Navigate to={`${prefix}/login#start`} replace />;
	}

	if (!event.params.isOpenEvent && !isLoggedIn) {
		return <Navigate to={`${prefix}/login#start`} replace />;
	}

	if (!showQuery.isSuccess) {
		return null;
	}

	const renderBlock = (block: EventBlock) => {
		switch (block.element.type) {
			case 'event_description':
				return <DescriptionBlock />;
			case 'documents':
				return documentsCount > 0 && <DocumentBlock />;
			case 'speakers':
				return (
					showQuery.data.speakers.length > 0 && (
						<SpeakersBlock speakers={showQuery.data.speakers} />
					)
				);
			case 'program':
				return (
					showQuery.data.program.length > 0 && (
						<ProgramBlock program={showQuery.data.program} />
					)
				);
			case 'partners':
				return (
					showQuery.data.partners.length > 0 && (
						<PartnersBlock partners={showQuery.data.partners} />
					)
				);
			case 'gallery':
				return (
					showQuery.data.photos.length > 0 && (
						<GalleryBlock photos={showQuery.data.photos} />
					)
				);
			case 'location':
				return <LocationBlock />;
			case 'contact':
				return <ContactOrganizerBlock />;
		}
	};

	return (
		<>
			<Header />
			<DetailsBlock />

			{!isDesignMode ? (
				blocks.map((block) => (
					<React.Fragment key={block.id}>{renderBlock(block)}</React.Fragment>
				))
			) : (
				<DndContext
					onDragStart={handleDragStart}
					onDragEnd={handleDragEnd}
					sensors={sensors}
				>
					{blocks.map((block, index) => {
						const blockComponent = renderBlock(block);
						return (
							<SortableItem
								key={block.id}
								id={block.id}
								index={index}
								disabled={!blockComponent}
								active={active}
							>
								{blockComponent}
							</SortableItem>
						);
					})}

					<DragOverlay dropAnimation={null}>
						{active ? <DragHandle /> : null}
					</DragOverlay>
				</DndContext>
			)}
		</>
	);
}

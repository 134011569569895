import { isAfter, parseISO } from 'date-fns';

export const isEventOver = (endDate: string | null) => {
	if (!endDate) {
		return false;
	}

	const date = parseISO(endDate);

	return !isAfter(date, Date.now());
};

import { Image } from '@invityou/types';
import { useEvent } from '../../hooks/useEvent';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { isEventOver } from '../../utils/isEventOver';
import { Link } from 'react-router-dom';
import { useAbsolutePath } from '../../hooks/useAbsolutePath';

export const ContactOrganizerBlock = () => {
	const { __ } = useTranslate();
	const event = useEvent();
	const { prefix } = useAbsolutePath();

	if (!event.email_organizer) {
		return null;
	}

	const organizerImg = event.organizer as Image | null;

	const showOrganizerDetails =
		(event.firstname_organizer !== null && event.firstname_organizer !== '') ||
		(event.company_organizer !== null && event.company_organizer !== '') ||
		(event.phone_organizer !== null && event.phone_organizer !== '') ||
		(event.url_organizer !== null && event.url_organizer !== '');

	const showOrganizerAddress =
		(event.address_organizer !== null && event.address_organizer !== '') ||
		(event.zip_organizer !== null && event.zip_organizer !== '') ||
		(event.city_organizer !== null && event.city_organizer !== '') ||
		(event.country_organizer !== null && event.country_organizer !== '');

	return (
		<section
			id="event-organizer"
			className={`${event.template.organizer_block ? '' : 'hide'}`}
		>
			<div className="container section-container">
				<h3
					id="event-organizer-heading"
					className={`title-organizer custom-font-subtitle ${
						event.template.organizer_title === null ||
						event.template.organizer_title === ''
							? 'hide'
							: ''
					}`}
				>
					{event.template.organizer_title}
				</h3>

				<div className="content-organizer custom-font-content">
					{organizerImg && (
						<div className="block-organizer img-organizer">
							<img src={organizerImg.url} className="fo_event_organizer" />
						</div>
					)}

					{showOrganizerDetails && (
						<div className="block-organizer">
							<address className="details-organizer">
								{event.firstname_organizer && (
									<>
										{event.firstname_organizer} {event.lastname_organizer}
										<br />
									</>
								)}

								{event.company_organizer && (
									<>
										{event.company_organizer}
										<br />
									</>
								)}

								{event.phone_organizer && <>{event.phone_organizer}</>}

								{event.phone_organizer && event.url_organizer && <br />}

								{event.url_organizer && (
									<a
										href={event.url_organizer}
										target="_blank"
										rel="noopener noreferrer"
									>
										{event.url_organizer.replace(/^https?:\/\//, '')}
									</a>
								)}
							</address>
						</div>
					)}

					{showOrganizerAddress && (
						<div className="block-organizer infos-organizer">
							<address className="details-organizer">
								{event.address_organizer && <>{event.address_organizer}</>}

								{(event.zip_organizer || event.city_organizer) && (
									<>
										<br />
										{event.zip_organizer && <>{event.zip_organizer}</>}{' '}
										{event.city_organizer && <>{event.city_organizer}</>}
									</>
								)}

								{event.country_organizer && (
									<>
										<br />
										{event.country_organizer}
									</>
								)}
							</address>
						</div>
					)}
				</div>

				{!isEventOver(event.end_date) && (
					<div className="all-elements">
						<Link
							to={`${prefix}/contact#start`}
							className="btn btn-secondary btn-lg"
						>
							{__('Contact form')}
						</Link>
					</div>
				)}
			</div>
		</section>
	);
};

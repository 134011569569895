import { useQuery } from '@tanstack/react-query';
import { useDocTitle } from '../../../../../../../shared/hooks/useDocTitle';
import { useTranslate } from '../../../../../../../shared/hooks/useTranslate';
import axios from 'axios';
import { useAbsolutePath } from '../../../../../hooks/useAbsolutePath';
import { Link } from 'react-router-dom';
import { useProtectedAuth } from '../../../../../hooks/useAuth';

export default function UserGodsons() {
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();
	const { user } = useProtectedAuth();

	useDocTitle(__('My companions'));

	const companionsQuery = useQuery({
		queryKey: ['user_companions', prefix],
		queryFn: async () => {
			const { data } = await axios.get(`${prefix}/users/companionsJson`);
			return data as {
				companions: {
					id: string;
					firstname: string;
					lastname: string;
					mail: string;
					ebadgeUrl: string | null;
				}[];
			};
		},
		refetchOnWindowFocus: false,
	});

	return (
		<section>
			<div className="container section-container">
				<div className="form">
					<h2 className="form-title">
						<ol className="breadcrumb">
							<li>
								<Link to={`${prefix}/member#start`}>
									{user.firstname || user.lastname
										? `${user.firstname} ${user.lastname}`
										: user.mail}
								</Link>
							</li>
							<li>{__('Companions')}</li>
						</ol>
					</h2>

					{companionsQuery.isSuccess && (
						<ul className="list-inline list-card">
							{companionsQuery.data.companions.map((godson) => {
								return (
									<li key={godson.id}>
										<div className="card">
											<div className="card-content">
												<span className="card-title">
													{godson.firstname} {godson.lastname}
												</span>
											</div>
											<div className="card-content">{godson.mail}</div>
											<div className="card-content text-right">
												{godson.ebadgeUrl && (
													<a
														href={godson.ebadgeUrl}
														className="btn btn-secondary"
														target="_blank"
														rel="noreferrer"
													>
														{__('Download e-badge')}
													</a>
												)}
											</div>
										</div>
									</li>
								);
							})}
						</ul>
					)}
				</div>
			</div>
		</section>
	);
}

import { createContext } from 'react';
import ReactModal from 'react-modal';

const { Provider, Consumer } = createContext({
	onRequestClose: () => {},
});

interface Props {
	isOpen: boolean;
	onRequestClose: () => void;
	shouldCloseOnOverlayClick?: boolean;
	children?: React.ReactNode;
	extraClassName?: string;
}

const getParent = () => {
	return document.body as HTMLElement;
};

export const Modal = ({
	isOpen,
	onRequestClose,
	children,
	shouldCloseOnOverlayClick = true,
	extraClassName,
}: Props) => (
	<ReactModal
		isOpen={isOpen}
		style={{
			overlay: {
				display: 'block',
				backgroundColor: 'rgba(0, 0, 0, 0.5)',
			},
			content: {
				outline: 'none',
			},
		}}
		className={`modal-dialog ${extraClassName}`}
		overlayClassName="modal fade in"
		onRequestClose={onRequestClose}
		parentSelector={getParent}
		ariaHideApp={false}
		shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
	>
		<div className="modal-content">
			<Provider value={{ onRequestClose }}>{children}</Provider>
		</div>
	</ReactModal>
);

export const ModalBody = ({
	children,
	style = {},
}: {
	style?: React.CSSProperties;
	children?: React.ReactNode;
}) => {
	return (
		<div className="modal-body" style={style}>
			{children}
		</div>
	);
};

export const ModalHeader = ({ children }: { children?: React.ReactNode }) => {
	return (
		<div className="modal-header">
			<Consumer>
				{({ onRequestClose }) => (
					<button className="close" onClick={onRequestClose}>
						<span aria-hidden="true">×</span>
					</button>
				)}
			</Consumer>

			<h4 className="modal-title">{children}</h4>
		</div>
	);
};

export const ModalFooter = ({ children }: { children?: React.ReactNode }) => {
	return <div className="modal-footer">{children}</div>;
};

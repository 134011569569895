export const FormInput = ({
	name,
	label,
	type,
	errors,
	required = false,
	value,
	onChange,
}: {
	name: string;
	label: string;
	type: 'text' | 'textarea';
	errors: Record<string, string | undefined> | null;
	required?: boolean;
	value: string;
	onChange: (value: string) => void;
}) => {
	return (
		<div
			className={`form-group ${required ? 'required' : ''} ${
				errors && errors[name] ? 'form-error' : ''
			}`}
		>
			<label className="control-label" htmlFor={name}>
				{label}
			</label>
			<div className="form-col">
				{type === 'text' ? (
					<input
						name={name}
						type={type}
						id={name}
						className="form-control"
						required={required}
						value={value}
						onChange={(e) => {
							onChange(e.target.value);
						}}
					/>
				) : (
					<textarea
						name={name}
						id={name}
						className="form-control"
						required={required}
						value={value}
						onChange={(e) => {
							onChange(e.target.value);
						}}
					></textarea>
				)}
				{errors && errors[name] && (
					<div className="help-block text-error">{errors[name]}</div>
				)}
			</div>
		</div>
	);
};

import { Link } from 'react-router-dom';
import { useEvent } from '../../hooks/useEvent';
import { useAbsolutePath } from '../../hooks/useAbsolutePath';
import { useLayoutConfig } from '../../hooks/useLayoutConfig';
import { Image } from '@invityou/types';
import { useEffect, useRef } from 'react';
import { useAuth } from '../../hooks/useAuth';

const FormButton = () => {
	const { prefix } = useAbsolutePath();
	const event = useEvent();

	const headerBtnText = event.template.header_btn_text;
	const surveyBtnText = event.template.survey_btn_text;

	const { user } = useAuth();
	const status = user?.status ?? null;

	const shouldDisplaySurveyButton =
		!event.template.survey_btn_hidden && event.openSurvey;
	const shouldDisplayRegistrationButton =
		!event.template.header_btn_hidden &&
		(event.openReply || !shouldDisplaySurveyButton);

	return (
		<>
			{shouldDisplayRegistrationButton && (
				<Link
					to={
						status !== null
							? `${prefix}/member#start`
							: `${prefix}/register#start`
					}
					id="event-rsvp"
					className="btn btn-primary btn-lg"
				>
					{headerBtnText}
				</Link>
			)}

			{shouldDisplaySurveyButton && (
				<Link
					to={`${prefix}/survey#start`}
					id="event-survey"
					className="btn btn-primary btn-lg masthead-btn"
				>
					{surveyBtnText}
				</Link>
			)}
		</>
	);
};

interface Props {
	hideFormButton?: boolean;
}

const Header = ({ hideFormButton = false }: Props) => {
	const event = useEvent();
	const layoutConfig = useLayoutConfig();

	const carouselImages = [...layoutConfig.carousel];
	const carouselTransition = event.template.carousel_transition ?? '';
	const carouselTime_interval = event.template.carousel_time_interval * 1000;
	const carouselControl = event.template.carousel_control ?? false;
	const isCarouselEnabled = layoutConfig.carousel.length > 0;

	const carouselRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (carouselRef.current) {
			$(carouselRef.current).carousel();
		}
	}, []);

	if (carouselImages.length === 0 && event.banner) {
		const banner = event.banner as Image;
		carouselImages.push(banner);
	}

	const headerTitleText = event.template.header_title_text;
	const headerSubtitleText = event.template.header_subtitle_text;

	return (
		<header
			id="event-header"
			className={`
				header-image-${event.template.header_image_fit}
				header-text-horizontal-${event.template.header_text_alignement_horizontal}
				header-text-vertical-${event.template.header_text_alignement_vertical}
			`}
		>
			<div
				ref={carouselRef}
				className={`masthead carousel ${carouselTransition}`}
				data-interval={carouselTime_interval}
			>
				<div className="masthead-carousel-inner carousel-inner" role="listbox">
					{carouselImages.length > 0 ? (
						carouselImages.map((image, index) => {
							if (image.url === null) {
								return null;
							}

							return (
								<div
									key={index}
									className={`carousel-item item ${
										index + 1 === 1 ? 'active' : ''
									} `}
								>
									<img id={`event-banner-${index + 1}`} src={image.url}></img>
								</div>
							);
						})
					) : (
						<div className="carousel-item item active">
							<img id="event-banner-1" />
						</div>
					)}
				</div>

				<div className="masthead-overlay"></div>

				<div className="masthead-container">
					<div className="masthead-content">
						<h1
							id="event-heading"
							className={`masthead-heading custom-font-title ${
								headerTitleText === null || headerTitleText === '' ? 'hide' : ''
							}`}
						>
							{headerTitleText}
						</h1>

						<div
							id="event-lead"
							className={`masthead-lead custom-font-subtitle ${
								headerSubtitleText === null || headerSubtitleText === ''
									? 'hide'
									: ''
							}`}
						>
							{headerSubtitleText}
						</div>

						{!hideFormButton && <FormButton />}
					</div>
				</div>

				<a
					href="#event-header"
					className={`left carousel-control ${
						isCarouselEnabled && carouselControl ? '' : 'hide'
					}`}
					role="button"
					data-slide="prev"
				>
					<i className="fa fa-angle-left fa-3x" aria-hidden="true"></i>
				</a>
				<a
					href="#event-header"
					className={`right carousel-control ${
						isCarouselEnabled && carouselControl ? '' : 'hide'
					}`}
					role="button"
					data-slide="next"
				>
					<i className="fa fa-angle-right fa-3x" aria-hidden="true"></i>
				</a>
			</div>

			<div className="mobile-button">{!hideFormButton && <FormButton />}</div>
		</header>
	);
};

export default Header;

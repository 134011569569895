import { DraggableAttributes, useDraggable } from '@dnd-kit/core';
import { forwardRef } from 'react';
import { SyntheticListenerMap } from '@dnd-kit/core/dist/hooks/utilities';

export const DragHandle = forwardRef<
	HTMLDivElement,
	{
		diameter?: number;
		attributes?: DraggableAttributes;
		listeners?: SyntheticListenerMap;
	}
>(({ diameter = 30, ...props }, ref) => {
	return (
		<div
			ref={ref}
			style={{
				width: diameter,
				height: diameter,
				borderRadius: diameter / 2,
				textAlign: 'center',
				backgroundColor: 'var(--info-color)',
				display: 'flex',
				alignItems: 'center',
				justifyContent: 'center',
				cursor: 'pointer',
			}}
			{...props}
		>
			<span className="fa fa-arrows" style={{ color: '#FFFFFF' }}></span>
		</div>
	);
});
DragHandle.displayName = 'DragHandle';

export const Draggable = ({ id, index }: { id: string; index: number }) => {
	const { attributes, listeners, setNodeRef } = useDraggable({
		id,
		data: { index },
	});

	return (
		<div
			style={{
				position: 'absolute',
				left: '50%',
				top: 0,
				transform: 'translate(-50%, -50%)',
				zIndex: 100,
			}}
		>
			<DragHandle ref={setNodeRef} {...attributes} {...listeners} />
		</div>
	);
};

import { useState } from 'react';

type AlertType = 'success' | 'info' | 'warning' | 'danger';

const getTypeClass = (type: AlertType): string => {
	switch (type) {
		case 'success':
			return 'alert-success';
		case 'info':
			return 'alert-info';
		case 'warning':
			return 'alert-warning';
		case 'danger':
			return 'alert-danger';
	}
};

interface Props {
	type: AlertType;
	children?: React.ReactNode;
	dismissible?: boolean;
}

const Alert = ({ type = 'info', children, dismissible = false }: Props) => {
	const [isDismissed, setIsDismissed] = useState(false);

	if (isDismissed) {
		return null;
	}

	return (
		<div
			className={`alert ${getTypeClass(type)} ${
				dismissible ? 'alert-dismissible' : ''
			}`}
			role="alert"
		>
			{dismissible && (
				<button
					type="button"
					className="close"
					aria-label="Close"
					onClick={() => {
						setIsDismissed(true);
					}}
				>
					<span aria-hidden="true">×</span>
				</button>
			)}

			{children}
		</div>
	);
};

export default Alert;

import { useQuery } from '@tanstack/react-query';
import { useDocTitle } from '../../../../../../../shared/hooks/useDocTitle';
import { useEvent } from '../../../../../hooks/useEvent';
import { useTranslate } from '../../../../../../../shared/hooks/useTranslate';
import { useAbsolutePath } from '../../../../../hooks/useAbsolutePath';
import axios from 'axios';
import { useAuth } from '../../../../../hooks/useAuth';
import { useSearchParams } from 'react-router-dom';

const IS_EXPECTED = 4;

export default function RegistrationComplete() {
	const event = useEvent();
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();
	const { user } = useAuth();

	useDocTitle(`${__('Register form')} | ${event.name}`);

	const [searchParams] = useSearchParams();
	const testFormId = searchParams.get('formId');

	const registrationCompleteQuery = useQuery({
		queryKey: ['registration_complete', prefix, testFormId],
		queryFn: async () => {
			const { data } = await axios.get(`${prefix}/forms/registerSuccessJson`, {
				params: { formId: testFormId },
			});
			return data as {
				hasDeclined: boolean;
				message: string | null;
				ebadgeMessage: string | null;
				ebadgeUrl: string | null;
			};
		},
	});

	return (
		<section id="register-success">
			<div className="container section-container">
				{registrationCompleteQuery.isSuccess && (
					<div className="form">
						{registrationCompleteQuery.data.message &&
						registrationCompleteQuery.data.message !== '' ? (
							<div
								dangerouslySetInnerHTML={{
									__html: registrationCompleteQuery.data.message,
								}}
							></div>
						) : !registrationCompleteQuery.data.hasDeclined ? (
							<p>
								{__(
									'Your registration is complete, thank you for your participation.'
								)}
							</p>
						) : (
							<p>{__('Thank you for answering the registration form.')}</p>
						)}

						{event.params.ebadges_enabled &&
							user?.status === IS_EXPECTED &&
							registrationCompleteQuery.data.ebadgeUrl !== null && (
								<>
									{registrationCompleteQuery.data.ebadgeMessage &&
									registrationCompleteQuery.data.ebadgeMessage !== '' ? (
										<div
											dangerouslySetInnerHTML={{
												__html: registrationCompleteQuery.data.ebadgeMessage,
											}}
										></div>
									) : (
										<p>
											<a
												href={registrationCompleteQuery.data.ebadgeUrl}
												download="ebadge.pdf"
											>
												{__('Download my ebadge')}
											</a>
										</p>
									)}
								</>
							)}
					</div>
				)}
			</div>
		</section>
	);
}

import { useTranslate } from '../../../../shared/hooks/useTranslate';

export const OrderStatusBadge = ({ status }: { status: string }) => {
	const { __ } = useTranslate();

	let statusText = status;
	switch (status) {
		case 'completed':
			statusText = __('Validated');
			break;
		case 'refunded':
			statusText = __('Refunded');
			break;
		case 'cancelled':
			statusText = __('Cancelled');
			break;
		case 'awaiting_payment':
			statusText = __('Awaiting payment');
			break;
	}

	return (
		<span className={`badge badge-${status} pull-right`}>{statusText}</span>
	);
};

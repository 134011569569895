import { useQuery } from '@tanstack/react-query';
import { useAbsolutePath } from '../useAbsolutePath';
import axios from 'axios';

export const useCompanionsOptionsQuery = (userId: string | undefined) => {
	const { prefix } = useAbsolutePath();

	return useQuery({
		queryKey: ['companions_options', prefix, userId],
		queryFn: async () => {
			const { data } = await axios.get(`${prefix}/users/addCompanionsJson`);

			return data as {
				formId: string | null;
				displayRecommendations: boolean;
				displayGodsons: boolean;
			};
		},
	});
};

import { CardElement } from '@stripe/react-stripe-js';
import { useForm } from 'react-hook-form';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { getCakeLang } from '../../../../i18n';
import { User } from '../../types/ticketing';

export const canSubmit = (
	paymentInProgress: boolean,
	{
		terms,
		name,
		address,
		zip,
		city,
	}: {
		terms: boolean;
		name: string;
		address: string;
		zip: string;
		city: string;
	}
): boolean => {
	return (
		!paymentInProgress &&
		terms &&
		name !== '' &&
		address !== '' &&
		zip !== '' &&
		city !== ''
	);
};

export const getFullName = (user: User | undefined) => {
	return `${user?.firstname ?? ''} ${user?.lastname ?? ''}`.trim();
};

const getInitialValues = (mode: string, user: User | undefined) => {
	return {
		name: getFullName(user),
		address: '',
		address_zip: '',
		city: '',
		siret: '',
		number: mode === 'test' ? '4111111111111111' : '',
		exp_month: mode === 'test' ? '02' : '',
		exp_year: mode === 'test' ? '2025' : '',
		cvc: mode === 'test' ? '848' : '',
		terms: false,
	};
};

export type PaymentFormData = ReturnType<typeof getInitialValues>;

interface Props {
	mode: string;
	onSubmit: (data: PaymentFormData) => void;
	paymentInProgress: boolean;
	testMode: boolean;
	user: User | undefined;
}

const PaymentForm = ({
	mode,
	onSubmit,
	paymentInProgress,
	testMode,
	user,
}: Props) => {
	const { register, handleSubmit, watch } = useForm({
		defaultValues: getInitialValues(mode, user),
	});
	const { __, lang } = useTranslate();
	const terms = watch('terms');
	const name = watch('name');
	const address = watch('address');
	const zip = watch('address_zip');
	const city = watch('city');

	return (
		<>
			<div className="text-center" style={{ marginBottom: 35 }}>
				<p>
					<span style={{ fontWeight: 'bold' }}>
						{__('Fill in your credit card infos')}
					</span>
					<br />
					<span style={{ color: '#737373', fontSize: '14px' }}>
						{__('These informations will appear in the invoice')}
					</span>
				</p>
			</div>

			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="form-group">
					<label htmlFor="name">
						{__('Full name')} <span className="required">*</span>
					</label>
					<input
						type="text"
						id="name"
						className="form-control"
						{...register('name')}
					/>
				</div>
				<div className="form-group">
					<label htmlFor="siret">{__('SIRET number')} </label>
					<input
						type="text"
						id="siret"
						className="form-control"
						{...register('siret')}
					/>
				</div>

				<div className="form-group">
					<label htmlFor="address">
						{__('Address')} <span className="required">*</span>
					</label>
					<input
						type="text"
						id="address"
						className="form-control"
						{...register('address')}
					/>
				</div>

				<div className="row">
					<div className="col-md-6">
						<div className="form-group">
							<label htmlFor="address_zip">
								{__('ZIP code')} <span className="required">*</span>
							</label>
							<input
								type="text"
								id="address_zip"
								className="form-control"
								{...register('address_zip')}
							/>
						</div>
					</div>

					<div className="col-md-6">
						<div className="form-group">
							<label htmlFor="city">
								{__('City')} <span className="required">*</span>
							</label>
							<input
								type="text"
								id="city"
								className="form-control"
								{...register('city')}
							/>
						</div>
					</div>
				</div>

				<div className="form-group">
					<label htmlFor="number">
						{__('Card Number')} <span className="required">*</span>
					</label>
					<CardElement
						className="form-control"
						options={{
							style: {
								base: {
									fontSize: '16px',
									color: 'rgb(85, 85, 85)',
									fontFamily: '"Source Sans Pro", sans-serif',
								},
							},
							hidePostalCode: true,
						}}
					/>
					{testMode && (
						<div
							className="alert alert-warning"
							style={{ marginTop: '10px' }}
							role="alert"
						>
							<p>
								{__(
									'You can test different scenarios using the following card numbers :'
								)}
							</p>
							<ul>
								<li>
									<span style={{ userSelect: 'all' }}>4000 0000 0000 3220</span>{' '}
									{__('to test 3D Secure 2 payments')}
								</li>
								<li>
									<span style={{ userSelect: 'all' }}>4000 0000 0000 3063</span>{' '}
									{__('to test 3D Secure payments')}
								</li>
								<li>
									<span style={{ userSelect: 'all' }}>4242 4242 4242 4242</span>{' '}
									{__('to test simple payments')}
								</li>
							</ul>
							<p>
								{__(
									'You can use anything you want for the expiration date and cvc number.'
								)}
							</p>
						</div>
					)}
				</div>

				<div className="form-group">
					<div className="checkbox">
						<label htmlFor="terms">
							<input type="checkbox" id="terms" {...register('terms')} />
							{__(
								'Click here to indicate that you have read and agree to the terms presented in the'
							)}{' '}
							<a
								href={`/files/legal/tos_ticketing_${getCakeLang(lang)}.pdf`}
								target="_blank"
								rel="noopener noreferrer"
							>
								{__('terms of use')}
							</a>
							.<span className="required">*</span>
						</label>
					</div>
				</div>

				<div style={{ fontStyle: 'italic' }}>
					<span className="required">*</span> {__('Required field')}
				</div>

				<div style={{ textAlign: 'center' }}>
					<button
						type="submit"
						className={`btn btn-ticketing-2 btn-primary ${
							paymentInProgress ? 'btn-loading' : ''
						}`}
						disabled={
							!canSubmit(paymentInProgress, { terms, name, address, zip, city })
						}
					>
						{__('Pay order')}
					</button>
				</div>
			</form>
		</>
	);
};

export default PaymentForm;

import { Link, Navigate } from 'react-router-dom';
import { useDocTitle } from '../../../../../shared/hooks/useDocTitle';
import { useTranslate } from '../../../../../shared/hooks/useTranslate';
import { useAbsolutePath } from '../../../hooks/useAbsolutePath';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../../hooks/useApi';
import { Checkin } from '@invityou/types';

export default function CheckinsIndex() {
	const { prefix } = useAbsolutePath();
	const { __ } = useTranslate();
	const api = useApi();

	useDocTitle(__('Checkins'));

	const checkinsQuery = useQuery({
		queryKey: ['checkins'],
		queryFn: async () => {
			const { data } = await api.fetchCheckins({ type: 'online' });
			return data as Checkin[];
		},
	});

	const checkins =
		checkinsQuery.data?.filter((checkin) => checkin.display_on_event_page) ??
		[];

	if (checkins.length === 1) {
		return (
			<Navigate to={`${prefix}/checkins/${checkins[0].id}#start`} replace />
		);
	}

	return (
		<section id="event-documents-single">
			<div className="container section-container">
				<ul>
					{checkins.map((checkin) => {
						return (
							<li key={checkin.id}>
								<div className="title-documents">
									<Link
										to={`${prefix}/checkins/${checkin.id}#start`}
										className="download-svg-documents"
									>
										{checkin.name}
									</Link>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		</section>
	);
}

import { useEvent } from '../../hooks/useEvent';

export const DescriptionBlock = () => {
	const event = useEvent();

	if (event.description === null || event.description === '') {
		return null;
	}

	return (
		<section
			id="event-description"
			className={`${event.template.description_block ? '' : 'hide'}`}
		>
			<div className="container section-container">
				<h2
					id="event-description-heading"
					className={`section-heading custom-font-title ${
						event.template.description_title === null ||
						event.template.description_title === ''
							? 'hide'
							: ''
					}`}
				>
					{event.template.description_title}
				</h2>

				<div
					className="content-description custom-font-content"
					dangerouslySetInnerHTML={{ __html: event.description }}
				></div>
			</div>
		</section>
	);
};

import TicketCard, { areConditionsFulfilled } from './TicketCard';
import { Ticket, Category, Order, User } from '../../types/ticketing';

interface Props {
	category: Category;
	categories: Category[];
	tickets: Ticket[];
	ticketError: Error | null;
	user: User;
	order: Order;
	addTicket: (ticketId: string, userId: string) => void;
	removeTicket: (ticketId: string, userId: string) => void;
}

const shouldHideCategory = (
	category: Category,
	categories: Category[],
	tickets: Ticket[],
	user: User
): boolean => {
	const categoryTickets = category.items
		.filter((item) => item.type === 'ticket')
		.map((item) => item.id)
		.map((ticketId) => tickets.find((ticket) => ticket.id === ticketId));

	// Check that a least one ticket inside the category fulfill our users conditions
	for (const ticket of categoryTickets) {
		if (!ticket) {
			return false;
		}

		const isBought = user.tickets
			.map((item) => item.ticket)
			.includes(ticket.id);
		const shouldDisplay =
			isBought || (ticket.active && areConditionsFulfilled(ticket, user));

		if (shouldDisplay) {
			return false;
		}
	}

	const subCategories = category.items
		.filter((item) => item.type === 'category')
		.map((item) => item.id)
		.map((categoryId) =>
			categories.find((category) => category.id === categoryId)
		);
	// Recursively check tickets for all subcategories
	return subCategories.every(
		(subCategory) =>
			subCategory && shouldHideCategory(subCategory, categories, tickets, user)
	);
};

const CategoryCard = (props: Props) => {
	const {
		category,
		categories,
		tickets,
		ticketError,
		user,
		order,
		addTicket,
		removeTicket,
	} = props;

	const { color } = category;

	// Hide category if conditions are met by none of the guests
	if (shouldHideCategory(category, categories, tickets, user)) {
		return null;
	}

	const items = category.items.map((item) => {
		switch (item.type) {
			case 'category': {
				const category = categories.find((category) => category.id === item.id);

				return (
					category && (
						<CategoryCard
							key={category.id}
							category={category}
							categories={categories}
							tickets={tickets}
							ticketError={ticketError}
							user={user}
							order={order}
							addTicket={addTicket}
							removeTicket={removeTicket}
						/>
					)
				);
			}
			case 'ticket': {
				const ticket = tickets.find((ticket) => ticket.id === item.id);
				return (
					ticket &&
					ticket.active && (
						<TicketCard
							key={ticket.id}
							ticket={ticket}
							color={color}
							user={user}
							order={order}
							addTicket={addTicket}
							removeTicket={removeTicket}
							error={ticketError}
						/>
					)
				);
			}
			default:
				return null;
		}
	});

	return (
		<div className="category">
			<div style={{ marginBottom: '1rem', color: color }}>{category.name}</div>
			<div className="tickets">{items}</div>
		</div>
	);
};

export default CategoryCard;

import { Navigate, useParams } from 'react-router-dom';
import { useDocTitle } from '../../../../../../shared/hooks/useDocTitle';
import { useTranslate } from '../../../../../../shared/hooks/useTranslate';
import { useAbsolutePath } from '../../../../hooks/useAbsolutePath';
import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../../../hooks/useApi';
import { Checkin } from '@invityou/types';
import { useAuth } from '../../../../hooks/useAuth';
import { LiveCheckin } from '../../../../components/checkins/LiveCheckin';

export default function OnlineCheckin() {
	const { prefix } = useAbsolutePath();
	const { __ } = useTranslate();
	const { isLoggedIn } = useAuth();
	const api = useApi();
	const { id } = useParams() as { id: string };

	useDocTitle(__('Live'));

	const checkinQuery = useQuery({
		queryKey: ['checkins', id],
		queryFn: async () => {
			const { data } = await api.fetchCheckin(id);
			return data as Checkin;
		},
	});

	const checkin = checkinQuery.data;

	if (checkin && !checkin.is_public && !isLoggedIn) {
		return <Navigate to={`${prefix}/login#start`} replace />;
	}

	return (
		<section>
			<div className="container section-container">
				{checkin && <LiveCheckin checkin={checkin} />}
			</div>
		</section>
	);
}

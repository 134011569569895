import { createContext, useContext, useMemo } from 'react';
import InvityouApi from '../../../api';
import { useEventSlug } from '../../../shared/hooks/useEventSlug';
import { useTranslate } from '../../../shared/hooks/useTranslate';
import { useAuth } from './useAuth';

const account = document.body.dataset.account;

const ApiContext = createContext<InvityouApi | null>(null);

interface Props {
	children: React.ReactNode;
}

export const ApiProvider = ({ children }: Props) => {
	const eventSlug = useEventSlug();
	const { lang } = useTranslate();
	const { token } = useAuth();

	const api = useMemo(
		() =>
			new InvityouApi({
				account,
				event: eventSlug,
				lang,
				token: token ?? undefined,
			}),
		[eventSlug, lang, token]
	);

	return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>;
};

export const useApi = () => {
	const api = useContext(ApiContext);

	if (!api) {
		throw new Error(
			'Api not found. Did you use ApiProvider before calling useApi ?'
		);
	}

	return api;
};

import { useState } from 'react';
import { useDocTitle } from '../../../../../shared/hooks/useDocTitle';
import { useEvent } from '../../../hooks/useEvent';
import { useTranslate } from '../../../../../shared/hooks/useTranslate';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useAbsolutePath } from '../../../hooks/useAbsolutePath';
import { User } from '@invityou/types';
import { useDebounce } from '../../../../../shared/hooks/useDebounce';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../../hooks/useAuth';
import { css } from '@emotion/css';

const GUEST_PER_ROW = 3;

export default function Participants() {
	const event = useEvent();
	const { prefix } = useAbsolutePath();
	const { __ } = useTranslate();

	const participantsTitle =
		event.template.participants_title &&
		event.template.participants_title !== ''
			? event.template.participants_title
			: __('Attendees');

	useDocTitle(`${participantsTitle} | ${event.name}`);

	const [search, setSearch] = useState('');

	const debouncedSearch = useDebounce(search, 500);

	const options = { search: debouncedSearch };

	const listUsersQuery = useQuery({
		queryKey: ['list_users', prefix, options],
		queryFn: async () => {
			const { data } = await axios.get(`${prefix}/users/listJson`, {
				params: options,
			});

			return data as Pick<
				User,
				'id' | 'firstname' | 'lastname' | 'company' | 'job_title'
			>[];
		},
		placeholderData: keepPreviousData,
		retry: false,
	});

	const users = listUsersQuery.data ?? [];

	const { isLoggedIn } = useAuth();
	if (!event.params.isOpenEvent && !isLoggedIn) {
		return <Navigate to={`${prefix}/login#start`} replace />;
	}

	const numberOfRows = Math.ceil(users.length / GUEST_PER_ROW);

	return (
		<section className="section-long">
			<div
				id="participants"
				className="container section-container custom-font-content"
			>
				{!event.template.participants_block ? (
					<p className="custom-font-content">
						{__('The list of attendees is not available.')}
					</p>
				) : (
					<>
						<h2 className="section-heading custom-font-title">
							{participantsTitle} ({users.length})
						</h2>

						<div className="form row">
							<div className="col-sm-8 col-sm-offset-2">
								<div className="form-group has-feedback">
									<div className="input-group">
										<span className="input-group-addon">
											<i className="fa fa-search fa-fw" aria-hidden="true"></i>
										</span>
										<input
											type="text"
											className="form-control"
											name="search"
											value={search}
											onChange={(e) => {
												setSearch(e.target.value);
											}}
											placeholder={__('Search for a name')}
										/>
									</div>
									<span className="icon-helper form-control-feedback hide">
										<i className="fa fa-times fa-fw" aria-hidden="true"></i>
									</span>
								</div>
							</div>
						</div>

						{users.length === 0 && search !== '' ? (
							<p className="participants-empty">
								{__('There are no attendees corresponding to your search.')}
							</p>
						) : users.length === 0 ? (
							<p className="participants-empty">
								{__('There are no attendees.')}
							</p>
						) : (
							<div
								className={css`
									display: grid;
									grid-template-columns: repeat(${GUEST_PER_ROW}, 1fr);
								`}
							>
								{users.map((user, index) => {
									const currentRow = Math.ceil((index + 1) / GUEST_PER_ROW);
									const isLastRow = currentRow === numberOfRows;
									return (
										<div
											key={user.id}
											className={css`
												display: grid;
												grid-template-rows: subgrid;
												grid-row: span 3;
												justify-content: center;
												${!isLastRow &&
												`border-bottom: 1px solid
												rgb(from var(--textvariant) r g b / 0.4);`}
											`}
										>
											<p
												className={css`
													text-align: center;
													font-size: 1.2em;
													margin: 10px 0;
													padding: 0 20px;
												`}
											>
												{`${user.firstname} ${user.lastname}`.toUpperCase()}
											</p>
											<p
												className={css`
													text-align: center;
													margin-bottom: ${user.job_title ? '10px' : '0'};
													padding: 0 20px;
												`}
											>
												{user.job_title}
											</p>
											<p
												className={css`
													text-align: center;
													margin-bottom: ${user.company ? '10px' : '0'};
													padding: 0 20px;
												`}
											>
												{user.company}
											</p>
										</div>
									);
								})}
							</div>
						)}
					</>
				)}
			</div>
		</section>
	);
}

import { LanguageShort } from '../../../i18n';

export type TranslatedValue = Partial<
	Record<LanguageShort, string | null>
> | null;

export const translate = (
	translatedValue: TranslatedValue,
	languageCode: LanguageShort
): string => {
	return translatedValue?.[languageCode] ?? '';
};

export const translateWithFallback = (
	translatedValue: TranslatedValue,
	languageCode: LanguageShort,
	fallbackLanguageCode: LanguageShort
): string => {
	return (
		translate(translatedValue, languageCode) ||
		translate(translatedValue, fallbackLanguageCode)
	);
};

export const getDefaultLang = (
	translatedValue: TranslatedValue
): LanguageShort => {
	const DEFAULT_LANG = 'fr';

	const languages = ['en', 'fr', 'es'] as const;
	for (const code of languages) {
		const content = translatedValue?.[code];
		if (content && content !== '') {
			return code;
		}
	}

	return DEFAULT_LANG;
};

export const translateWithDefaultFallback = (
	translatedValue: TranslatedValue,
	languageCode: LanguageShort
) => {
	return translateWithFallback(
		translatedValue,
		languageCode,
		getDefaultLang(translatedValue)
	);
};

export const setTranslations = (
	translatedValue: TranslatedValue,
	translations: TranslatedValue
): TranslatedValue => {
	return { ...translatedValue, ...translations };
};

export const setTranslation = (
	translatedValue: TranslatedValue,
	languageCode: LanguageShort,
	text: string
) => {
	return setTranslations(translatedValue, { [languageCode]: text });
};

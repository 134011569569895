export const slugify = (text: string): string => {
	return (
		text
			.toLowerCase()
			.slice(0, 50)
			.trim()
			// Remove accents
			.normalize('NFD')
			.replaceAll(/[\u0300-\u036F]/g, '')
			.replaceAll(/[^\d\sa-z-]+/g, '') // Remove all non valid chars
			.replaceAll(/\s+/g, '-') // Replace whitespace
	);
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import Api from '../../api';
import { Event } from '@invityou/types';
import { LanguageShort } from '../../i18n';

const QUERY_KEY = 'events';

type DeepPartial<T> = {
	[P in keyof T]?: DeepPartial<T[P]>;
};

export const useUpdateEvent = (
	api: Api,
	slug: string,
	lang?: LanguageShort
) => {
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (values: DeepPartial<Event>) => {
			const { data } = await api.updateEvent(slug, values, lang);
			return data as Event;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: [QUERY_KEY],
			});
		},
	});
};

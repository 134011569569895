import { useParams } from 'react-router-dom';

const getPrefix = (lang?: string, eventSlug?: string) => {
	return `${lang ? `/${lang}` : ''}${eventSlug ? `/${eventSlug}` : ''}`;
};

export const useAbsolutePath = () => {
	const { lang, eventSlug } = useParams();

	return {
		prefix: getPrefix(lang, eventSlug),
		withPrefix: (path: string, slug: string | undefined = eventSlug) =>
			`${getPrefix(lang, slug)}${path}`,
	};
};

/**
 * Get valid tinymce language
 */
export const getTinyMceLang = function (lang: string): string {
	const availableLangs = ['en', 'fr_FR'] as const;
	const defaultLang = availableLangs[0];

	for (const availableLang of availableLangs) {
		if (availableLang === lang || availableLang.slice(0, 2) === lang) {
			return availableLang;
		}
	}

	return defaultLang;
};

import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { LanguageShort } from '../../../i18n';
import { Event } from '@invityou/types';
import { useApi } from './useApi';

export const useEventQuery = (
	eventSlug: string,
	queryOptions = {},
	lang?: LanguageShort,
	enabled = true
) => {
	const api = useApi();

	return useQuery({
		queryKey: ['events', eventSlug, queryOptions, lang],
		queryFn: async () => {
			const { data } = await api.fetchEvent(eventSlug, queryOptions, lang);
			return data as Event;
		},
		refetchOnWindowFocus: false,
		staleTime: 5000,
		placeholderData: keepPreviousData,
		enabled,
	});
};

import React, { useState } from 'react';
import { useTranslate } from '../../../shared/hooks/useTranslate';
import { Modal, ModalHeader, ModalBody, ModalFooter } from './Modal';

interface Props {
	title?: string;
	content?: string | JSX.Element;
	confirmBtnName?: string;
	confirmBtnAction: (closeModal: () => void) => void;
	loading?: boolean;
	children: (openModal: () => void) => React.ReactNode;
	extraClassName?: string;
}

const ConfirmActionModal = ({
	title,
	content,
	confirmBtnName,
	confirmBtnAction,
	loading,
	children,
	extraClassName,
}: Props) => {
	const { __ } = useTranslate();

	const [modalIsOpen, setModalIsOpen] = useState(false);

	const openModal = () => {
		setModalIsOpen(true);
	};

	const closeModal = () => {
		setModalIsOpen(false);
	};

	return (
		<>
			{children(openModal)}

			<Modal
				isOpen={modalIsOpen}
				onRequestClose={closeModal}
				extraClassName={extraClassName}
			>
				<ModalHeader>{title ?? __('Notice')}</ModalHeader>

				<ModalBody>
					{content ?? __('Do you really want to do this action?')}
				</ModalBody>

				<ModalFooter>
					<div style={{ display: 'flex' }}>
						<div style={{ flexShrink: 0, flexGrow: 1 }}>
							<button className="btn btn-default" onClick={closeModal}>
								{__('Cancel')}
							</button>
							<button
								className={`btn btn-warning ${loading ? 'btn-loading' : ''}`}
								disabled={loading}
								onClick={() => {
									confirmBtnAction(closeModal);
								}}
							>
								{confirmBtnName ?? __('Confirm')}
							</button>
						</div>
					</div>
				</ModalFooter>
			</Modal>
		</>
	);
};

export default ConfirmActionModal;

import { AxiosResponse } from 'axios';
import { Order, OrderItem } from '../../types/ticketing';
import Api from '../../../../api';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { Stripe, StripeElements } from '@stripe/stripe-js';
import { ConfirmOrderForm } from '../../../../admin/react/types/users';
import { useApi } from '../../hooks/useApi';

const addTicket =
	(api: Api) =>
	async (ticket: string, user: string, previousOrder: Order | undefined) => {
		if (!previousOrder) {
			throw new Error('Order not set.');
		}

		const item: OrderItem = { ticket, user };

		const newItems = [...previousOrder.items, item];

		// Update order
		try {
			const response = await api.updateOrder(previousOrder.id, {
				items: newItems,
			});
			return response.data as Order;
		} catch (err) {
			const message =
				(err &&
					err.response &&
					err.response.data &&
					err.response.data.message) ||
				'Unknown error';

			throw new Error(message);
		}
	};

const removeTicket =
	(api: Api) =>
	async (ticket: string, user: string, previousOrder: Order | undefined) => {
		if (!previousOrder) {
			throw new Error('Order not set.');
		}

		const item = { ticket, user };

		const newItems = previousOrder.items.filter(
			(t) => t.ticket !== item.ticket || t.user !== item.user
		);

		try {
			const response = await api.updateOrder(previousOrder.id, {
				items: newItems,
			});
			return response.data as Order;
		} catch (err) {
			const message =
				(err &&
					err.response &&
					err.response.data &&
					err.response.data.message &&
					(err.response.data.details
						? Object.values(err.response.data.details).join(', ')
						: err.response.data.message)) ||
				'Unknown error';

			throw new Error(message);
		}
	};

const addCoupon =
	(api: Api) => async (code: string, previousOrder: Order | undefined) => {
		if (!previousOrder) {
			throw new Error('Order not set.');
		}

		const newCoupons = [...previousOrder.coupons, code];

		try {
			const response = await api.updateOrder(previousOrder.id, {
				coupons: newCoupons,
			});
			return response.data as Order;
		} catch (err) {
			const message =
				(err &&
					err.response &&
					err.response.data &&
					err.response.data.message &&
					(err.response.data.details
						? Object.values(err.response.data.details).join(', ')
						: err.response.data.message)) ||
				'Unknown error';

			throw new Error(message);
		}
	};

const removeCoupon =
	(api: Api) => async (code: string, previousOrder: Order | undefined) => {
		if (!previousOrder) {
			throw new Error('Order not set.');
		}

		const newCoupons = previousOrder.coupons.filter((c) => c !== code);

		try {
			const response = await api.updateOrder(previousOrder.id, {
				coupons: newCoupons,
			});
			return response.data as Order;
		} catch (err) {
			const message =
				(err &&
					err.response &&
					err.response.data &&
					err.response.data.message &&
					(err.response.data.details
						? Object.values(err.response.data.details).join(', ')
						: err.response.data.message)) ||
				'Unknown error';

			throw new Error(message);
		}
	};

const awaitOrder =
	(api: Api) =>
	async (previousOrder: Order | undefined, formValues: ConfirmOrderForm) => {
		if (!previousOrder) {
			throw new Error('Order not found.');
		}

		try {
			const res = await api.awaitOrder(previousOrder.id, formValues);

			if (res.data.status !== 'awaiting_payment') {
				throw new Error('Order not awaiting payment.');
			}
		} catch (err) {
			throw new Error(err.response.data.message);
		}
	};

export const payOrder =
	(api: Api) =>
	async (
		{
			name,
			address,
			zip,
			city,
			siret,
		}: {
			name?: string;
			address?: string;
			zip?: string;
			city?: string;
			siret?: string;
		},
		order: Order | undefined,
		elements: StripeElements | null,
		stripe: Stripe | null
	) => {
		if (!order) {
			throw new Error('Order not found.');
		}

		let paymentMethodId = null;

		if (stripe === null || elements === null) {
			throw new Error('Stripe error.');
		}

		if (order.amount > 0) {
			const cardElement = elements.getElement(CardElement);

			if (cardElement === null) {
				return;
			}

			const { error, paymentMethod } = await stripe.createPaymentMethod({
				type: 'card',
				card: cardElement,
				billing_details: {
					name: name,
					address: {
						line1: address,
						city: city,
						postal_code: zip,
					},
				},
			});

			if (error) {
				throw new Error(error.message);
			}

			if (!paymentMethod) {
				throw new Error('No payment method.');
			}

			paymentMethodId = paymentMethod.id;
		}

		const id = order.id;

		try {
			const { data } = await api.payOrder(
				id as string,
				paymentMethodId,
				name as string,
				address as string,
				zip as string,
				city as string,
				siret as string,
				null
			);

			if (data.requires_action) {
				const { error, paymentIntent } = await stripe.handleCardAction(
					data.payment_intent_client_secret
				);

				if (error) {
					throw new Error(error.message);
				}

				if (!paymentIntent) {
					throw new Error('No payment intent.');
				}

				const res = await api.payOrder(
					id as string,
					null,
					name as string,
					address as string,
					zip as string,
					city as string,
					siret as string,
					paymentIntent.id
				);

				if (res.data.status !== 'completed') {
					throw new Error('Order failed.');
				}
			} else {
				if (data.status !== 'completed') {
					throw new Error('Order failed.');
				}
			}
		} catch (err) {
			throw new Error(err?.response?.data?.message ?? err.message);
		}
	};

export const useOrderQuery = (userId: string, mode: string) => {
	const api = useApi();
	return useQuery({
		// TODO: stop relying on query key for ticketing to work
		// eslint-disable-next-line @tanstack/query/exhaustive-deps
		queryKey: ['order'],
		queryFn: async () => {
			const { data }: AxiosResponse<Order[]> =
				await api.fetchUserOrders(userId);
			const createdOrders = data
				.filter((order) => order.status === 'created')
				.filter((order) => order.mode === mode);

			if (createdOrders.length > 0) {
				return createdOrders.at(-1) as Order;
			} else {
				const { data } = await api.createOrder({ customer: userId, mode });
				return data as Order;
			}
		},
	});
};

export const useAddTicketMutation = () => {
	const api = useApi();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ ticket, user }: OrderItem) => {
			const order = queryClient.getQueryData<Order>(['order']);
			const newOrder = await addTicket(api)(ticket, user, order);
			return newOrder;
		},
		onSuccess: (order) => {
			queryClient.setQueryData<Order>(['order'], order);
		},
	});
};

export const useRemoveTicketMutation = () => {
	const api = useApi();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async ({ ticket, user }: OrderItem) => {
			const order = queryClient.getQueryData<Order>(['order']);
			const newOrder = await removeTicket(api)(ticket, user, order);
			return newOrder;
		},
		onSuccess: (order) => {
			queryClient.setQueryData<Order>(['order'], order);
		},
	});
};

export const useAddCouponMutation = () => {
	const api = useApi();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (code: string) => {
			const order = queryClient.getQueryData<Order>(['order']);
			return await addCoupon(api)(code, order);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ['order'],
			});
		},
	});
};

export const useRemoveCouponMutation = () => {
	const api = useApi();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (code: string) => {
			const order = queryClient.getQueryData<Order>(['order']);
			return await removeCoupon(api)(code, order);
		},
		onSettled: () => {
			queryClient.invalidateQueries({
				queryKey: ['order'],
			});
		},
	});
};

export const useAwaitOrderMutation = () => {
	const api = useApi();
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (params: { formValues: ConfirmOrderForm }) => {
			const { formValues } = params;
			const order = queryClient.getQueryData<Order>(['order']);
			return awaitOrder(api)(order, formValues);
		},
	});
};

export const usePayOrderMutation = () => {
	const api = useApi();
	const queryClient = useQueryClient();
	const elements = useElements();
	const stripe = useStripe();
	return useMutation({
		mutationFn: (params: {
			name?: string;
			address?: string;
			zip?: string;
			city?: string;
			siret?: string;
		}) => {
			const order = queryClient.getQueryData<Order>(['order']);
			return payOrder(api)(params, order, elements, stripe);
		},
	});
};

import Jed from 'jed';
import { fra, spa } from './translations';

type CakeLang = 'fra' | 'eng' | 'spa';
export type LanguageShort = 'fr' | 'en' | 'es';

export const getCakeLang = (locale: LanguageShort): CakeLang => {
	switch (locale) {
		case 'fr':
			return 'fra';
		case 'es':
			return 'spa';
		default:
			return 'eng';
	}
};

const getLocaleData = (locale: CakeLang) => {
	switch (locale) {
		case 'fra':
			return fra;
		case 'spa':
			return spa;
		default:
			return {
				messages: {
					'': {
						domain: 'messages',
						lang: 'en',
						'plural-forms': 'nplurals=2; plural=(n != 1);',
					},
				},
			};
	}
};

export const createI18n = (lang: LanguageShort) => {
	return new Jed({
		domain: 'messages',
		locale_data: getLocaleData(getCakeLang(lang)),
	});
};

const lang = (document.querySelector('html') as HTMLElement).getAttribute(
	'lang'
) as LanguageShort | null;

const i18n = createI18n(lang ?? 'en');

export type TranslateFn = (
	input: string,
	...args: (string | number)[]
) => string;

export const __Proto = (i18n: Jed): TranslateFn => {
	return (input, ...args) => i18n.translate(input).fetch(...args);
};

export type TranslatePluralFn = (
	singular: string,
	plural: string,
	count: number,
	...args: (string | number)[]
) => string;

export const __nProto = (i18n: Jed): TranslatePluralFn => {
	return (singular, plural, count, ...args) =>
		i18n
			.translate(singular)
			.ifPlural(count, plural)
			.fetch(...args);
};

export const defaultLang = 'fr' as const;

export const __ = __Proto(i18n);

export const __n = __nProto(i18n);

import { Godson } from '@invityou/types/bin/api/Users';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { Link } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useEvent } from '../../hooks/useEvent';

export const getEbadgeUrl = (
	userId: string,
	eventId: string,
	domainName: string
) => {
	return `https://s3.invityou.com/${
		__DEV__ ? 'dev.invityou.localhost' : domainName
	}/app/webroot/img/events/${eventId}/ebadges/${userId}.pdf`;
};

const checkEbadge = async (id: string, eventId: string, domainName: string) => {
	const url = getEbadgeUrl(id, eventId, domainName);

	await axios.head(url);

	return {
		id,
		url,
	};
};

interface Props {
	godsons: Godson[];
}

export const Godsons = ({ godsons }: Props) => {
	const { __ } = useTranslate();

	const event = useEvent();

	const areEbadgesEnabled = event.params.ebadges_enabled;

	const godsonsEbadgesQuery = useQuery({
		queryKey: [
			'godson_ebdages',
			godsons.map((godson) => godson.id),
			event.id,
			event.domain_name,
		],
		queryFn: async () => {
			const godsonsEbadges: Record<string, string | null> = {};

			const promises = [];

			for (const godson of godsons) {
				godsonsEbadges[godson.id] = null;

				if (godson.status !== 4) {
					continue;
				}

				promises.push(checkEbadge(godson.id, event.id, event.domain_name));
			}

			const responses = await Promise.allSettled(promises);

			for (const response of responses) {
				if (response.status === 'fulfilled') {
					const { id, url } = response.value;
					godsonsEbadges[id] = url;
				}
			}

			return godsonsEbadges;
		},
		enabled: areEbadgesEnabled,
	});

	if (areEbadgesEnabled && !godsonsEbadgesQuery.isSuccess) {
		return (
			<>
				<div style={{ textAlign: 'center', margin: '100px 0' }}>
					<i className="fa fa-spinner fa-spin fa-lg" />
					<span className="sr-only">{__('Loading...')}</span>
				</div>
			</>
		);
	}

	const showAddGodsonsButton =
		godsons.length < (event.params.companions_max ?? Number.POSITIVE_INFINITY);

	return (
		<div id="companions">
			<div className="form" id="companions-list">
				<h2 className="form-title">{__('Companions')}</h2>

				{godsons.length === 0 ? (
					<p style={{ marginBottom: 50 }}>
						{__('You have not added any companion yet.')}
					</p>
				) : (
					<ul className="list-inline list-card">
						{godsons.map((godson) => {
							const ebadgeUrl = godsonsEbadgesQuery.isSuccess
								? godsonsEbadgesQuery.data[godson.id]
								: null;
							return (
								<li key={godson.id}>
									<div className="card card-companions">
										<div className="card-content">
											<div className="media">
												<div className="media-body">
													<div>
														<strong>
															{godson.firstname} {godson.lastname}
														</strong>
													</div>
													<div className="card__mail">{godson.mail}</div>
												</div>
												<div className="media-right">
													<Link
														key={godson.id}
														to={`godsons/${godson.id}`}
														className="btn btn-circle btn-secondary"
													>
														<i className="fa fa-pencil"></i>
													</Link>
													<br></br>
													<br></br>
													{ebadgeUrl && (
														<a
															href={ebadgeUrl}
															className="btn btn-circle btn-secondary"
															download
														>
															<i
																className="fa fa-id-badge"
																aria-hidden="true"
															></i>
														</a>
													)}
												</div>
											</div>
										</div>
									</div>
								</li>
							);
						})}
					</ul>
				)}

				<div className="form-action">
					{showAddGodsonsButton && (
						<Link to={`godsons`} className="btn btn-secondary">
							{__('Add a companion')}
						</Link>
					)}
				</div>
			</div>
		</div>
	);
};

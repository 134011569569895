import { GroupFromServer, QuestionFromServer } from '../../types/magicform';

const areConditionsFulfilled = (
	group: GroupFromServer,
	items: GroupFromServer[]
) => {
	const conditionsFulfilled: boolean[] = [];

	for (const condition of group.conditions) {
		const associatedQuestion = findQuestionById(condition.question_id, items);
		if (
			associatedQuestion &&
			areQuestionConditionsFulfilled(associatedQuestion, items)
		) {
			if (associatedQuestion.input_type_id === 'checkbox') {
				const answer = associatedQuestion.answer as Record<
					string,
					boolean
				> | null;
				const option = associatedQuestion.options.find(
					(option) => option.id === condition.option_id
				);
				if (option && answer) {
					conditionsFulfilled.push(
						answer[option.value] && answer[option.value] === true
					);
				} else {
					conditionsFulfilled.push(false);
				}
			} else {
				const answer = associatedQuestion.answer;
				const option = associatedQuestion.options.find(
					(option) => option.value === answer
				);
				const selectedOptionId = option?.id ?? null;
				conditionsFulfilled.push(condition.option_id === selectedOptionId);
			}
		} else {
			conditionsFulfilled.push(false);
		}
	}

	const operator = group.logical_operator;

	if (operator === 'AND') {
		return conditionsFulfilled.every((value) => value === true);
	} else if (operator === 'OR') {
		return conditionsFulfilled.includes(true);
	}
	return false;
};

const findQuestionById = (
	id: string,
	items: GroupFromServer[]
): QuestionFromServer | undefined => {
	let foundQuestion;

	for (const group of items) {
		const foundQuestionIndex = group.questions.findIndex((y) => y.id === id);
		if (foundQuestionIndex !== -1) {
			foundQuestion = group.questions[foundQuestionIndex];
		}
	}

	return foundQuestion;
};

const areQuestionConditionsFulfilled = (
	question: QuestionFromServer,
	items: GroupFromServer[]
) => {
	const groupToCheck = items.find((group) => group.id === question.group_id);
	if (groupToCheck) {
		return areConditionsFulfilled(groupToCheck, items);
	}
	return false;
};

export { findQuestionById, areQuestionConditionsFulfilled };

import { useQuery } from '@tanstack/react-query';
import { useDocTitle } from '../../../../../../../shared/hooks/useDocTitle';
import { useEvent } from '../../../../../hooks/useEvent';
import { useTranslate } from '../../../../../../../shared/hooks/useTranslate';
import { useAbsolutePath } from '../../../../../hooks/useAbsolutePath';
import axios from 'axios';

export default function SurveyComplete() {
	const event = useEvent();
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();

	useDocTitle(`${__('Survey')} | ${event.name}`);

	const surveyCompleteQuery = useQuery({
		queryKey: ['survey_complete', prefix],
		queryFn: async () => {
			const { data } = await axios.get(`${prefix}/forms/surveySuccessJson`);
			return data as {
				message: string | null;
			};
		},
	});

	return (
		<section id="survey-success">
			<div className="container section-container">
				{surveyCompleteQuery.isSuccess && (
					<div className="form">
						{surveyCompleteQuery.data.message &&
						surveyCompleteQuery.data.message !== '' ? (
							<div
								dangerouslySetInnerHTML={{
									__html: surveyCompleteQuery.data.message,
								}}
							></div>
						) : (
							<p className="text-center">
								{__('Thank you for having answered our satisfaction survey.')}
							</p>
						)}
					</div>
				)}
			</div>
		</section>
	);
}

// Hook
// T - could be any type of HTML element like: HTMLDivElement, HTMLParagraphElement and etc.

import { useCallback, useRef, useState } from 'react';

export const useHover = <T extends HTMLElement>(): [
	(node: T) => void,
	boolean,
] => {
	const [value, setValue] = useState(false);
	const ref = useRef<T | null>(null);
	const handleMouseOver = useCallback((e: PointerEvent) => {
		e.stopPropagation();
		setValue(true);
	}, []);
	const handleMouseOut = useCallback((e: PointerEvent) => {
		e.stopPropagation();
		setValue(false);
	}, []);

	const callbackRef = useCallback(
		(node: T) => {
			if (ref.current) {
				ref.current.removeEventListener('pointerover', handleMouseOver);
				ref.current.removeEventListener('pointerout', handleMouseOut);
			}

			ref.current = node;

			if (ref.current) {
				ref.current.addEventListener('pointerover', handleMouseOver);
				ref.current.addEventListener('pointerout', handleMouseOut);
			}
		},
		[handleMouseOver, handleMouseOut]
	);

	return [callbackRef, value];
};

import { useQuery } from '@tanstack/react-query';
import { MagicForm } from '../../../../../components/magicform/MagicForm';
import Alert from '../../../../../components/Alert';
import { useAuth } from '../../../../../hooks/useAuth';
import { useDocTitle } from '../../../../../../../shared/hooks/useDocTitle';
import { useEvent } from '../../../../../hooks/useEvent';
import { useTranslate } from '../../../../../../../shared/hooks/useTranslate';
import axios from 'axios';
import { useAbsolutePath } from '../../../../../hooks/useAbsolutePath';

export default function SurveyForm() {
	const event = useEvent();
	const { prefix } = useAbsolutePath();
	const { __, lang } = useTranslate();

	useDocTitle(`${__('Satisfaction survey')} | ${event.name}`);

	const { isLoggedIn, user } = useAuth();

	const formQuery = useQuery({
		queryKey: ['survey_data', prefix, user?.id],
		queryFn: async () => {
			const { data } = await axios.get(`${prefix}/users/surveyJson`);
			return data as {
				formId: string | null;
			};
		},
		enabled: isLoggedIn,
	});

	if (!isLoggedIn) {
		// TODO : Do redirect
		return (
			<section className="section-long">
				<div className="container section-container">
					<Alert type="danger">{__('You are not logged in.')}</Alert>
				</div>
			</section>
		);
	}

	if (formQuery.isPending) {
		return null;
	}

	const formId = formQuery.data?.formId;

	return (
		<section className="section-long">
			<div className="container section-container">
				{!formId ? (
					<div className="form text-center">
						<p>{__('Satisfaction survey disabled')}</p>
					</div>
				) : (
					<div className="questionnaire">
						<MagicForm
							config={{
								formId,
								lang,
								userId: user.id,
								presetValues: {
									'User.mail': user.mail,
								},
								hidePreset: ['User.mail'],
								isFormDisabled: false,
								maxCompanions: null,
							}}
						/>
					</div>
				)}
			</div>
		</section>
	);
}

import { useEvent } from '../../hooks/useEvent';
import { useTranslate } from '../../../../shared/hooks/useTranslate';

const ArchivedBanner = () => {
	const { __ } = useTranslate();
	const event = useEvent();

	if (event.archived_at === null) {
		return null;
	}

	return (
		<div
			style={{
				background: '#000',
				color: '#fff',
				padding: '40px',
				fontSize: 18,
				textAlign: 'center',
				position: 'fixed',
				bottom: 0,
				width: '100%',
				zIndex: 10,
			}}
		>
			{__('WARNING: This event is archived. Only organizers can access it.')}
		</div>
	);
};

export default ArchivedBanner;

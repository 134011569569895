import * as Sentry from '@sentry/browser';

const SENTRY_DSN =
	'https://7e3f8702a94845a0b6af841483812b1a@o69451.ingest.sentry.io/148504';

export const initSentry = () => {
	if (process.env.NODE_ENV !== 'development') {
		Sentry.init({ dsn: SENTRY_DSN });
	}
};

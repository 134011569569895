import { produce } from 'immer';
import { createContext, useContext, useState } from 'react';
import { Block } from './useUpdateCustomPageMutation';

interface BlockContext {
	blocks: Block[];
	setBlocks: (blocks: Block[]) => void;
	updateBlock: (blockId: string, blockData: Block) => void;
	removeBlock: (blockId: string) => void;
	addBlock: (blockData: Block) => void;
	addBlockAfter: (blockId: string, blockData: Block) => void;
}

const BlockContext = createContext<BlockContext | null>(null);

interface CustomPagesBlocksContainerProps {
	children: React.ReactNode;
}

export const CustomPageBlocksProvider = ({
	children,
}: CustomPagesBlocksContainerProps) => {
	const [blocks, setBlocks] = useState<Block[]>([]);

	const updateBlock = (blockId: string, blockData: Block) => {
		setBlocks(
			produce((draft) => {
				const index = draft.findIndex((block) => block.id === blockId);

				if (index !== -1) {
					draft[index] = blockData;
				}
			})
		);
	};

	const removeBlock = (blockId: string) => {
		setBlocks(
			produce((draft) => {
				const index = draft.findIndex((block) => block.id === blockId);

				if (index !== -1) {
					draft.splice(index, 1);
				}
			})
		);
	};

	const addBlock = (blockData: Block) => {
		setBlocks(
			produce((draft) => {
				draft.push(blockData);
			})
		);
	};

	const addBlockAfter = (blockId: string, blockData: Block) => {
		setBlocks(
			produce((draft) => {
				const index = draft.findIndex((block) => block.id === blockId);

				if (index !== -1) {
					draft.splice(index + 1, 0, blockData);
				}
			})
		);
	};

	return (
		<BlockContext.Provider
			value={{
				blocks,
				setBlocks,
				updateBlock,
				removeBlock,
				addBlock,
				addBlockAfter,
			}}
		>
			{children}
		</BlockContext.Provider>
	);
};

export const useBlocks = () => {
	const context = useContext(BlockContext);

	if (!context) {
		throw new Error(
			'BlockContext not found. Did you use CustomPageBlocksProvider before calling useBlocks ?'
		);
	}

	return context;
};

import { Loader } from '@googlemaps/js-api-loader';
import { useEvent } from '../../hooks/useEvent';
import { useEffect, useRef } from 'react';
import { useTranslate } from '../../../../shared/hooks/useTranslate';

const GMAPS_WEB_API_KEY = 'AIzaSyCaRAyp1S7VkQWqkt03Gzg1nrODLBi_Zso';

const GmapPreview = ({ lat, lon }: { lat: number; lon: number }) => {
	const { __ } = useTranslate();
	const mapRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		const loader = new Loader({
			apiKey: GMAPS_WEB_API_KEY,
		});
		Promise.all([
			loader.importLibrary('maps'),
			loader.importLibrary('marker'),
		]).then(([mapsLibrary, markerLibrary]) => {
			const latlng = new google.maps.LatLng(lat, lon);
			const element = mapRef.current;

			if (!element) {
				throw new Error("Google map can't be displayed.");
			}

			const map = new mapsLibrary.Map(element, {
				zoom: 16,
				center: latlng,
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				streetViewControl: false,
				mapTypeControl: false,
				scrollwheel: false,
				mapId: 'DEMO_MAP_ID',
			});

			// Diplay Marker
			new markerLibrary.AdvancedMarkerElement({
				position: latlng,
				map,
				title: __('Event location'),
			});
		});
	}, [lat, lon, __]);

	return <div ref={mapRef} id="gmap"></div>;
};

export const LocationBlock = () => {
	const event = useEvent();

	if (!event.lat || !event.lon) {
		return null;
	}

	return (
		<section
			id="event-location"
			className={`${event.template.location_block ? '' : 'hide'}`}
		>
			{(event.address ||
				event.zip ||
				event.city ||
				event.country ||
				event.address_description) && (
				<div className="container section-container">
					<h3
						id="event-location-heading"
						className={`title-localisation custom-font-subtitle ${
							!event.template.location_title ||
							event.template.location_title === ''
								? 'hide'
								: ''
						}`}
					>
						{event.template.location_title}
					</h3>

					<div className="custom-font-content">
						{(event.address || event.zip || event.city || event.country) && (
							<address className="address-localisation">
								{event.address_name && (
									<>
										<strong>{event.address_name}</strong>
										<br />
									</>
								)}
								{event.address && (
									<>
										{event.address}
										<br />
									</>
								)}
								{(event.city || event.zip) && (
									<>
										{event.zip} {event.city}
										<br />
									</>
								)}
								{event.country && (
									<>
										{event.country}
										<br />
									</>
								)}
							</address>
						)}

						{event.address_description && (
							<div className="address-localisation">
								<p>{event.address_description}</p>
							</div>
						)}
					</div>
				</div>
			)}

			<GmapPreview lat={event.lat} lon={event.lon} />
		</section>
	);
};

import { createContext, useContext, useState } from 'react';

const defaultState = false;

interface DesignContext {
	isDesignMode: boolean;
	setIsDesignMode: (value: boolean) => void;
}

const DesignContext = createContext<DesignContext | null>(null);

interface Props {
	children: React.ReactNode;
}

export const DesignProvider = ({ children }: Props) => {
	const [isDesignMode, setIsDesignMode] = useState(defaultState);

	const context = {
		isDesignMode,
		setIsDesignMode,
	};

	return (
		<DesignContext.Provider value={context}>{children}</DesignContext.Provider>
	);
};

export const useDesign = () => {
	const context = useContext(DesignContext);

	if (!context) {
		throw new Error(
			'Context not found. Did you use DesignProvider before calling useDesign ?'
		);
	}

	return context;
};

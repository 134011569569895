import { useEvent } from '../../hooks/useEvent';
import SocialNetworks from './SocialNetworks';
import WhiteLabelBanner from './WhiteLabelBanner';

const Footer = () => {
	const event = useEvent();
	return (
		<>
			<div
				className={`footer-social-network ${
					!event.params.isWhiteLabel ? 'footer-credits' : ''
				}`}
			></div>
			<footer>
				<SocialNetworks />
				<WhiteLabelBanner />
			</footer>
		</>
	);
};

export default Footer;

import { useState } from 'react';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SubmitButton } from '../SubmitButton';
import FormGroup from '../../../../shared/components/FormGroup';
import { slugify } from '../../../../admin/events/utils';
import { useApi } from '../../hooks/useApi';
import { setTranslation, translateWithDefaultFallback } from '../../utils/i18n';
import { useNavigate } from 'react-router-dom';
import { useAbsolutePath } from '../../hooks/useAbsolutePath';
import ConfirmActionModal from '../ConfirmActionModal';
import { TranslatedValue } from '../../utils/i18n';
import { useToast } from '../../hooks/useToast';
import { useBlocks } from '../../hooks/editor/useBlocks';
import {
	ValidationErrors,
	validateFormData,
} from '../../../../shared/utils/validate';
import { LayoutConfig } from '../../hooks/useLayoutConfig';
import { UpdatePageFormValidationSchema } from '../../types/editor';
import { getServerErrorMessage } from '../../../../shared/utils/errors';
import {
	CustomPage,
	useUpdateCustomPageMutation,
} from '../../hooks/editor/useUpdateCustomPageMutation';

interface Props {
	page: CustomPage;
}

export const EditPanel = ({ page }: Props) => {
	const { __, lang } = useTranslate();
	const { prefix } = useAbsolutePath();
	const api = useApi();
	const queryClient = useQueryClient();
	const navigate = useNavigate();
	const toast = useToast();

	const deleteCustomPageMutation = useMutation({
		mutationFn: async (shortId: string) => {
			await api.deleteCustomPage(shortId);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['custom_pages'] });
			toast(__('Page deleted.'), 'success');
		},
		onError: (err) => {
			toast(getServerErrorMessage(err, __('An error has ocurred.')), 'danger');
		},
	});

	const updateCustomPageMutation = useUpdateCustomPageMutation(page.short_id);

	const [validationErrors, setValidationErrors] =
		useState<ValidationErrors | null>(null);

	const validatePage = (formData: unknown) =>
		validateFormData(formData, UpdatePageFormValidationSchema, __);

	const [slug, setSlug] = useState(page.slug);
	const [title, setTitle] = useState(page.title);
	const [displayNav, setDisplayNav] = useState(page.display_nav);
	const [navTitle, setNavTitle] = useState(page.nav_title);
	const { blocks } = useBlocks();

	const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();
		const body: Partial<CustomPage> = {
			slug,
			title,
			display_nav: displayNav,
			nav_title: navTitle,
			blocks,
		};

		const errors = validatePage(body);
		if (errors) {
			setValidationErrors(errors);
			return;
		} else {
			setValidationErrors(null);
		}

		updateCustomPageMutation.mutate(body, {
			onSuccess: (page) => {
				// Update nav
				const el = document.querySelector('#layout-config');
				if (el) {
					const textContent = el.textContent as string;
					const layoutConfigData = JSON.parse(textContent) as LayoutConfig;

					let newCustomPagesNav = [];
					// Remove
					if (!page.display_nav) {
						newCustomPagesNav = layoutConfigData.customPagesNav.filter(
							(navItem) => navItem.short_id !== page.short_id
						);
					} else {
						const ids = layoutConfigData.customPagesNav.map(
							(navItem) => navItem.short_id
						);

						// Add
						if (!ids.includes(page.short_id)) {
							newCustomPagesNav = [...layoutConfigData.customPagesNav];
							newCustomPagesNav.push({
								short_id: page.short_id,
								slug: page.slug,
								nav_title: page.nav_title,
							});
						} else {
							// Update
							for (const navItem of layoutConfigData.customPagesNav) {
								newCustomPagesNav.push(
									navItem.short_id === page.short_id
										? { ...navItem, nav_title: page.nav_title }
										: navItem
								);
							}
						}
					}

					el.textContent = JSON.stringify({
						...layoutConfigData,
						customPagesNav: newCustomPagesNav,
					});
					queryClient.invalidateQueries({ queryKey: ['layout_config'] });
				}

				toast(__('Page updated.'), 'success');

				navigate(`${prefix}/pages/${page.short_id}/${page.slug}`);
			},
			onError: (err) => {
				toast(
					getServerErrorMessage(err, __('An error has ocurred.')),
					'danger'
				);
			},
		});
	};

	const getTranslation = (translatedValue: TranslatedValue) => {
		return translateWithDefaultFallback(translatedValue, lang);
	};

	return (
		<div
			className="admin-ui"
			style={{
				backgroundColor: '#ffffff',
				width: 400,
				alignSelf: 'stretch',
				padding: 10,
				borderLeft: '1px solid #ddd',
			}}
		>
			<div style={{ marginBottom: 15 }}>
				<ConfirmActionModal
					confirmBtnAction={(closeModal) => {
						deleteCustomPageMutation.mutate(page.short_id, {
							onSuccess: () => {
								// Update nav
								const el = document.querySelector('#layout-config');
								if (el) {
									const textContent = el.textContent as string;
									const layoutConfigData = JSON.parse(
										textContent
									) as LayoutConfig;
									const newCustomPagesNav =
										layoutConfigData.customPagesNav.filter(
											(navItem) => navItem.short_id !== page.short_id
										);
									el.textContent = JSON.stringify({
										...layoutConfigData,
										customPagesNav: newCustomPagesNav,
									});
									queryClient.invalidateQueries({
										queryKey: ['layout_config'],
									});
								}

								closeModal();
								navigate(`${prefix}`);
							},
						});
					}}
					content={__('Do you really want to delete this page ?')}
					loading={deleteCustomPageMutation.isPending}
					extraClassName="admin-ui"
				>
					{(openModal) => {
						return (
							<a role="button" className="btn" onClick={openModal}>
								<i className="fa fa-trash fa-fw" aria-hidden="true"></i>
							</a>
						);
					}}
				</ConfirmActionModal>

				<SubmitButton
					type="info"
					btnClass="pull-right"
					form="panel-update-page"
					loading={updateCustomPageMutation.isPending}
				>
					{__('Save')}
				</SubmitButton>
			</div>

			<form id="panel-update-page" onSubmit={handleSubmit}>
				<FormGroup
					id="slug"
					name="slug"
					label={__('URL of the page')}
					required
					validationErrors={validationErrors}
				>
					<div className="input-group">
						<span className="input-group-addon">{`.../pages/${page.short_id}/`}</span>
						<input
							type="text"
							className="form-control"
							name="slug"
							value={slug}
							onChange={(e) => {
								const slug = e.target.value;
								setSlug(slugify(slug));
							}}
						/>
					</div>
				</FormGroup>

				<FormGroup
					id="title"
					name="title"
					label={__('Title of the page')}
					required
					validationErrors={validationErrors}
				>
					<input
						type="text"
						className="form-control"
						name="title"
						value={getTranslation(title)}
						onChange={(e) => {
							const value = e.target.value;
							setTitle(setTranslation(title, lang, value));
						}}
					/>
				</FormGroup>

				<fieldset>
					<legend>{__('Menu')}</legend>

					<div className="form-group">
						<div className="checkbox required">
							<label htmlFor="display_nav">
								<input
									id="display_nav"
									name="display_nav"
									type="checkbox"
									checked={displayNav}
									onChange={(e) => {
										const checked = e.target.checked;
										setDisplayNav(checked);
									}}
								/>{' '}
								{__('Display in menu')}
							</label>
						</div>
					</div>

					<FormGroup
						id="nav_title"
						name="nav_title"
						label={__('Title to display in the menu')}
						required
						validationErrors={validationErrors}
					>
						<input
							type="text"
							className="form-control"
							name="nav_title"
							value={getTranslation(navTitle)}
							onChange={(e) => {
								const value = e.target.value;
								setNavTitle(setTranslation(navTitle, lang, value));
							}}
						/>
					</FormGroup>
				</fieldset>
			</form>
		</div>
	);
};

import { Link } from 'react-router-dom';
import { useEvent } from '../../hooks/useEvent';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { useAbsolutePath } from '../../hooks/useAbsolutePath';

export const DocumentBlock = () => {
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();
	const event = useEvent();

	const documentTitle =
		event.template.documents_title !== null &&
		event.template.documents_title !== ''
			? event.template.documents_title
			: __('Documents');

	return (
		<section
			id="event-documents"
			className={`${event.template.documents_block ? '' : 'hide'}`}
		>
			<div className="container section-container custom-font-content">
				<ul className="list-inline list-center">
					<li>
						<Link to={`${prefix}/documents`}>
							<span className="icon">
								<i className="fa fa-download" aria-hidden="true"></i>
							</span>
							<span id="event-documents-heading" className="text-document">
								{documentTitle}
							</span>
						</Link>
					</li>
				</ul>
			</div>
		</section>
	);
};

import { useTranslate } from '../../../../shared/hooks/useTranslate';

import { Ticket, Order, User } from '../../types/ticketing';

type status = 'purchased' | 'in-cart' | 'available' | 'unavailable';

const isTicketAvailable = (ticket: Ticket) => {
	return (
		ticket.valid &&
		!(
			ticket.max_quantity !== null &&
			ticket.times_redeemed + ticket.times_selected >= ticket.max_quantity
		)
	);
};

export const areConditionsFulfilled = (ticket: Ticket, user: User): boolean =>
	ticket.conditions.every((condition) => {
		switch (condition.type) {
			case 'customfield': {
				const customField = user.custom_fields[condition.field];

				switch (condition.value) {
					case 'is:empty': {
						// Empty or no
						let value = customField ? customField.value : '';
						value = value !== null ? value.toLowerCase() : '';

						return (
							customField && (value === '' || value === 'no' || value === 'non')
						);
					}
					default:
						return customField && customField.value === condition.value;
				}
			}
			case 'preset': {
				switch (condition.field) {
					case 'is_godson':
						return condition.value === 'yes'
							? user.godfather !== null
							: user.godfather === null;
					default:
						return true;
				}
			}
			default:
				return false;
		}
	});

const ellipsisDescription = (str: string, charlim: number): string => {
	return str.length > charlim
		? `${str.slice(0, Math.max(0, charlim - 1))} ...`
		: str;
};

interface Props {
	ticket: Ticket;
	color: string;
	user: User;
	order: Order;
	addTicket: (ticketId: string, userId: string) => void;
	removeTicket: (ticketId: string, userId: string) => void;
	error: Error | null;
}

const TicketCard = (props: Props) => {
	const getStatus = (user: User, ticket: Ticket, order: Order): status => {
		const isBought = user.tickets
			.map((item) => item.ticket)
			.includes(ticket.id);

		const isSelected = order.items
			.filter((item) => item.ticket === ticket.id)
			.map((item) => item.user)
			.includes(user.id);

		const isAvailable = isTicketAvailable(ticket);

		const conditionsFulfilled = areConditionsFulfilled(ticket, user);

		return isBought
			? 'purchased'
			: isSelected
				? 'in-cart'
				: isAvailable && conditionsFulfilled
					? 'available'
					: 'unavailable';
	};
	const { __ } = useTranslate();
	const { ticket, color, user, order, addTicket, removeTicket } = props;

	const status = getStatus(user, ticket, order);

	// Hide ticket
	if (
		!['in-cart', 'purchased'].includes(status) &&
		!areConditionsFulfilled(ticket, user)
	) {
		return null;
	}

	const isAvailable = isTicketAvailable(ticket);

	return (
		<div
			className={`${
				status === 'purchased' || status === 'in-cart'
					? 'ticket ticket-active'
					: 'ticket ticket-inactive'
			}`}
			style={{ borderColor: color, position: 'relative', cursor: 'pointer' }}
			onClick={() => {
				if (status === 'available') {
					addTicket(ticket.id, user.id);
				} else if (status === 'in-cart') {
					removeTicket(ticket.id, user.id);
				}
			}}
		>
			<div
				className="filling-ticket-active"
				style={{ backgroundColor: color }}
			></div>

			<div className="white-bar-left"></div>
			{status === 'in-cart' && (
				<div
					style={{
						backgroundColor: 'white',
						color: 'rgb(18, 115, 222)',
						right: -10,
						top: -10,
						position: 'absolute',
						border: `solid 2px ${color}`,
						borderRadius: '50%',
						zIndex: 15,
					}}
				>
					<i
						className="fa fa-check"
						aria-hidden="true"
						style={{ padding: 5, color: color }}
					></i>
				</div>
			)}
			{status === 'purchased' && (
				<div
					style={{
						backgroundColor: 'white',
						color: 'rgb(18, 115, 222)',
						right: -10,
						top: -10,
						position: 'absolute',
						border: `solid 2px rgb(18, 115, 222)`,
						padding: 5,
						zIndex: 15,
					}}
				>
					{__('You already own this ticket')}{' '}
				</div>
			)}

			<div className="heading">
				<span className="name">
					{ticket.name}{' '}
					{!ticket.allow_attendance && (
						<span className="badge">{__('Option')}</span>
					)}
				</span>

				<span className="price">
					{(ticket.price / 100).toLocaleString(undefined, {
						style: 'currency',
						currency: 'EUR',
					})}{' '}
					{__('incl taxes')}
				</span>
			</div>

			<div
				className="content"
				style={{ display: 'flex', flexDirection: 'column' }}
			>
				<div className="caption" style={{ zIndex: 10 }}>
					{ticket.caption}
				</div>
				<div className="description" style={{ zIndex: 10 }}>
					{status === 'purchased' || status === 'in-cart'
						? ticket.description
						: ellipsisDescription(ticket.description, 50)}
				</div>
			</div>

			<div style={{ display: 'flex' }}>
				<div style={{ flexGrow: 1 }}>
					{!isAvailable && (
						<div className="not-valid">
							{__('This ticket is not available anymore.')}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default TicketCard;

interface Props {
	loading: boolean;
	disabled?: boolean;
	type?: 'primary' | 'warning' | 'info';
	btnClass?: string;
	children: React.ReactNode;
	form?: string;
	onClick?: () => void;
}

export const SubmitButton = ({
	loading,
	disabled = false,
	type = 'primary',
	btnClass = '',
	children,
	form,
	onClick,
}: Props) => {
	return (
		<button
			type="submit"
			className={`btn btn-${type} ${loading ? 'btn-loading' : ''} ${btnClass}`}
			disabled={disabled || loading}
			form={form}
			onClick={onClick}
		>
			{children}
		</button>
	);
};

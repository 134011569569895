import { useRef, useEffect } from 'react';
import { Fancybox as NativeFancybox } from '@fancyapps/ui';
import '!!style-loader!css-loader!@fancyapps/ui/dist/fancybox/fancybox.css';

interface Props {
	delegate?: string;
	options?: any;
	children: JSX.Element;
}

export const Fancybox = (props: Props) => {
	const containerRef = useRef(null);

	useEffect(() => {
		const container = containerRef.current;

		const delegate = props.delegate || '[data-fancybox]';
		const options = props.options || {};

		NativeFancybox.bind(container, delegate, options);

		return () => {
			NativeFancybox.unbind(container);
			NativeFancybox.close();
		};
	});

	return <div ref={containerRef}>{props.children}</div>;
};

export const getServerErrorMessage = (
	err: any,
	defaultMessage: string,
	key?: string
): string => {
	const message: string | undefined = err?.response?.data?.message;

	const detailedMessage: string | undefined = key
		? err?.response?.data?.details?.[key]
		: undefined;

	return detailedMessage ?? message ?? defaultMessage;
};

export type ValidationErrors = Record<string, string> | null;

export const getValidationErrors = (err: any): ValidationErrors => {
	return err?.response?.data?.details ?? null;
};

import { useQuery } from '@tanstack/react-query';
import { useApi } from '../../../../hooks/useApi';
import { useDocTitle } from '../../../../../../shared/hooks/useDocTitle';
import { useTranslate } from '../../../../../../shared/hooks/useTranslate';
import { SharedFile } from '@invityou/types';
import { useAuth } from '../../../../hooks/useAuth';

export default function SharedFiles() {
	const { __ } = useTranslate();
	const api = useApi();
	const { user } = useAuth();

	useDocTitle(__('Shared files'));

	const userId = user?.id;

	const sharedFileQuery = useQuery({
		queryKey: ['sharedfiles', userId],
		queryFn: async () => {
			if (!userId) {
				return [];
			}

			const { data } = await api.fetchAllSharedFiles({
				user_id: userId,
			});

			return data as SharedFile[];
		},
	});

	return (
		<section>
			<div id="sharedfiles" className="container section-container">
				<h2 className="section-heading custom-font-title">
					{__('Shared files')}
				</h2>

				{sharedFileQuery.isPending ? (
					<div className="spinner-border text-primary" role="status">
						<span className="sr-only">{__('Loading...')}</span>
					</div>
				) : (
					<ul>
						{sharedFileQuery.data && sharedFileQuery.data.length > 0 ? (
							sharedFileQuery.data.map((sharedfile) => {
								return (
									<li key={sharedfile.id}>
										<div className="title-documents">
											<a
												className="download-svg-documents"
												href={sharedfile.download_url}
												target="_blank"
												rel="noreferrer"
											>
												<i className="fa fa-download" aria-hidden="true"></i>{' '}
												{sharedfile.name}
											</a>
										</div>
									</li>
								);
							})
						) : (
							<p>{__('No shared files.')}</p>
						)}
					</ul>
				)}
			</div>
		</section>
	);
}

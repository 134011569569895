import { Ticket, Order, User } from '../../types/ticketing';
import { Link } from 'react-router-dom';
import { usePayOrderMutation } from '../../queries/ticketing/order';
import { UseQueryResult } from '@tanstack/react-query';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { useAbsolutePath } from '../../hooks/useAbsolutePath';
import { useTicketingConfig } from './StripeContainer';

interface Item {
	id: string;
	quantity: number;
	name: string;
	price: number;
	price_pre_tax: number;
	appliedCoupons: {
		code: string;
		amount_off: number;
	}[];
	subTotal: number;
	subTotalPreTax: number;
}

const formatItems = (tickets: Ticket[], order: Order) => {
	const { items, coupons } = order;
	const allTickets = tickets;

	const orderItems: { id: string; quantity: number }[] = [];
	for (const orderItem of items) {
		const index = orderItems.findIndex((item) => item.id === orderItem.ticket);

		if (index === -1) {
			orderItems.push({ id: orderItem.ticket, quantity: 1 });
		} else {
			orderItems[index].quantity++;
		}
	}

	return (
		orderItems
			.map((item) => {
				const ticket = allTickets.find((t) => t.id === item.id);

				if (!ticket) {
					return null;
				}

				const appliedCoupons = ticket.coupons
					.filter((c) => coupons.includes(c.code))
					.map((coupon) => {
						const amountOff =
							coupon.amount_off ??
							ticket.price *
								(coupon.percent_off ? coupon.percent_off / 100 : 1);
						return { code: coupon.code, amount_off: amountOff };
					});

				const pricePreTax = ticket.price / (1 + ticket.tax_percent / 100);

				let subTotal = ticket.price * item.quantity;
				for (const coupon of appliedCoupons) {
					subTotal -= coupon.amount_off * item.quantity;
				}
				subTotal = Math.max(0, subTotal);

				let subTotalPreTax = pricePreTax * item.quantity;
				for (const coupon of appliedCoupons) {
					subTotalPreTax -= coupon.amount_off * item.quantity;
				}
				subTotalPreTax = Math.max(0, subTotalPreTax);

				return {
					id: item.id,
					quantity: item.quantity,
					name: ticket.full_name,
					price: ticket.price,
					price_pre_tax: pricePreTax,
					appliedCoupons: appliedCoupons,
					subTotal: subTotal,
					subTotalPreTax: subTotalPreTax,
				};
			})
			// Should never need this
			.filter((item) => item !== null) as Item[]
	);
};

const formatTicketsByUser = (order: Order) => {
	const { items } = order;
	const mapTicketsUser: Record<string, string[]> = {};

	for (const item of items) {
		mapTicketsUser[item.user] = mapTicketsUser[item.user]
			? [...mapTicketsUser[item.user], item.ticket]
			: [item.ticket];
	}

	return mapTicketsUser;
};

interface Props {
	tickets: Ticket[];
	order: Order;
	orderQuery: UseQueryResult<Order>;
	isOrderMutating: boolean;
	children?: React.ReactNode;
	users: User[];
}

const OrderSummary = ({
	tickets,
	order,
	children,
	orderQuery,
	isOrderMutating,
	users,
}: Props) => {
	const total = order.amount;
	const items = formatItems(tickets, order);
	const ticketsByUser = formatTicketsByUser(order);

	const payOrderMutation = usePayOrderMutation();
	const { __ } = useTranslate();
	const { prefix } = useAbsolutePath();

	const config = useTicketingConfig();
	const successUrl = config.successUrl;

	const redirectToSuccessPage = () => {
		window.location.href = successUrl;
	};

	const renderTickets = (tickets: Item[]) => {
		return users.map((user, i) => {
			return (
				<div key={user.id}>
					<div
						style={{
							marginRight: 'auto',
							marginLeft: 'auto',
							display: 'table',
							marginBottom: 15,
							fontWeight: 'bold',
							marginTop: i > 0 ? 30 : 0,
						}}
					>
						{!user.godfather
							? `${user.firstname} ${user.lastname}`
							: `${__('Companion')} ${i.toString()}`}
					</div>
					<ul>
						{ticketsByUser[user.id] ? (
							ticketsByUser[user.id].map((ticketMapping) => {
								const ticket = tickets.find((t) => t.id === ticketMapping);
								return ticket ? (
									<li key={ticket.id}>
										<div style={{ display: 'flex' }}>
											<span style={{ flexGrow: 1 }}>{ticket.name}</span>{' '}
											<span style={{ flexShrink: 0 }}>
												{(ticket.price / 100).toLocaleString(undefined, {
													style: 'currency',
													currency: 'EUR',
												})}{' '}
												(
												{(ticket.price_pre_tax / 100).toLocaleString(
													undefined,
													{
														style: 'currency',
														currency: 'EUR',
													}
												)}{' '}
												{__('excl tax')} )
											</span>
										</div>
										{ticket.appliedCoupons &&
											ticket.appliedCoupons.map((coupon) => (
												<div key={coupon.code} style={{ display: 'flex' }}>
													<span className="coupon-code" style={{ flexGrow: 1 }}>
														{coupon.code}
													</span>
													<span style={{ flexShrink: 0 }}>
														-
														{(
															(coupon.amount_off as number) / 100
														).toLocaleString(undefined, {
															style: 'currency',
															currency: 'EUR',
														})}{' '}
													</span>
												</div>
											))}
									</li>
								) : null;
							})
						) : (
							<div
								style={{
									marginRight: 'auto',
									marginLeft: 'auto',
									display: 'table',
								}}
							>
								{__('No ticket selected')}{' '}
							</div>
						)}
					</ul>
				</div>
			);
		});
	};

	return (
		<>
			<div
				className={`order-summary`}
				style={{
					border: 'solid 1px #ddd',
					borderTopLeftRadius: 8,
					padding: 30,
					borderTopRightRadius: 8,
					borderTop: `solid 11px rgb(18, 115, 222)`,
					zIndex: 99,
				}}
			>
				<div className="tickets">
					{items.length > 0
						? renderTickets(items)
						: __('No ticket selected yet.')}
				</div>

				<div className="total">
					<span className=" text-ticketing">{__('Total before taxes')}</span>
					<span className="amount">
						{(
							(total -
								(order as unknown as { total_taxes: number })['total_taxes']) /
							100
						).toLocaleString(undefined, {
							style: 'currency',
							currency: 'EUR',
						})}
					</span>
				</div>

				<div className="total">
					<span className=" text-ticketing">
						<strong>{__('Total after taxes')}</strong>
					</span>
					<span className="amount">
						<strong>
							{(total / 100).toLocaleString(undefined, {
								style: 'currency',
								currency: 'EUR',
							})}
						</strong>
					</span>
				</div>

				{children}

				{order && order.items.length > 0 && order.amount > 0 ? (
					<Link
						to={`${prefix}/ticketing/payment`}
						className={`btn btn-ticketing-2 btn-primary ${
							isOrderMutating ? 'btn-loading' : ''
						}`}
					>
						{__('Continue')}
					</Link>
				) : order && order.items.length > 0 && order.amount === 0 ? (
					<button
						disabled={payOrderMutation.isPending || orderQuery.isFetching}
						className={`btn btn-ticketing-2 btn-primary ${
							payOrderMutation.isPending ||
							orderQuery.isFetching ||
							isOrderMutating
								? 'btn-loading'
								: ''
						}`}
						onClick={() => {
							payOrderMutation.mutate(
								{},
								{
									onSuccess: () => {
										redirectToSuccessPage();
									},
								}
							);
						}}
					>
						{__('Complete order')}
					</button>
				) : (
					<button
						disabled
						className={`btn btn-ticketing-2 btn-primary ${
							isOrderMutating ? 'btn-loading' : ''
						}`}
					>
						{__('Continue')}
					</button>
				)}
			</div>

			{payOrderMutation.isError && (
				<div
					className="alert alert-danger"
					style={{ marginTop: '25px' }}
					role="alert"
				>
					{(payOrderMutation.error as Error).message}
				</div>
			)}
		</>
	);
};

export default OrderSummary;

import tinymce from 'tinymce/tinymce';
import 'tinymce/models/dom/model';
import 'tinymce/themes/silver';
import 'tinymce/icons/default';
import '!!style-loader!css-loader!tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce-i18n/langs6/fr_FR';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/link';
import 'tinymce/plugins/image';
import 'tinymce/plugins/table';
import contentCss from '!!raw-loader!tinymce/skins/content/default/content.min.css';
import contentUiCss from '!!raw-loader!tinymce/skins/ui/oxide/content.min.css';

export const defaultTinymceOptions = {
	branding: false,
	skin: false,
	content_css: false,
	content_style: [contentCss, contentUiCss].join('\n'),
};

export default tinymce;

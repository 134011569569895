import { useNavigate, useLocation, Path } from 'react-router-dom';

export const getUrlWithParam = (
	location: Path,
	param: string,
	value: string | string[]
): string => {
	const urlSearchParams = new URLSearchParams(location.search);

	if (Array.isArray(value) && value.length > 0) {
		for (const [index, item] of value.entries()) {
			if (index === 0) {
				urlSearchParams.set(param, item);
			} else {
				urlSearchParams.append(param, item);
			}
		}
	} else if (typeof value === 'string' && value !== '') {
		urlSearchParams.set(param, value);
	} else {
		urlSearchParams.delete(param);
	}

	return `${location.pathname}?${urlSearchParams.toString()}`;
};

export const useCustomQueryParams = <T = string>(
	name: string,
	defaultValue = '',
	parse: (input: string) => T,
	serialize: (input: T) => string
) => {
	const location = useLocation();
	const navigate = useNavigate();

	const urlSearchParams = new URLSearchParams(location.search);

	const rawValue = urlSearchParams.get(name);
	const value: T = parse(rawValue ?? defaultValue);

	const setValue = (value: T) => {
		const url = getUrlWithParam(location, name, serialize(value));
		navigate(url);
	};

	return [value, setValue] as const;
};

import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApi } from '../useApi';
import { TranslatedValue } from '../../utils/i18n';

export interface Block {
	id: string;
	element: { type: 'html'; content: TranslatedValue };
}

export interface CustomPage {
	id: string;
	event_id: string;
	short_id: string;
	slug: string;
	title: TranslatedValue;
	nav_title: TranslatedValue;
	blocks: Block[];
	public_page: boolean;
	display_nav: boolean;
	created: string;
	modified: string;
}

export const useUpdateCustomPageMutation = (shortId: string) => {
	const api = useApi();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: async (body: Partial<CustomPage>) => {
			const { data } = await api.updateCustomPage(shortId, body);
			return data as CustomPage;
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['custom_pages'] });
		},
	});
};

import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { StrictMode } from 'react';
import {
	RouteObject,
	createBrowserRouter,
	RouterProvider,
} from 'react-router-dom';
import Layout from './Layout';
import Show from './pages/[[lang]]/[event]';
import Legals from './pages/[[lang]]/[event]/legals';
import Contact from './pages/[[lang]]/[event]/contact';
import Participants from './pages/[[lang]]/[event]/participants';
import Documents from './pages/[[lang]]/[event]/documents';
import Photos from './pages/[[lang]]/[event]/photos';
import MemberArea from './pages/[[lang]]/[event]/(protected)/member';
import SharedFiles from './pages/[[lang]]/[event]/(protected)/sharedfiles';
import GodsonsAndRecommendations from './pages/[[lang]]/[event]/(protected)/register/other';
import AddOrEditGodson from './pages/[[lang]]/[event]/(protected)/register/other/godsons';
import EditGodson from './pages/[[lang]]/[event]/(protected)/register/other/godsons/[id]';
import AddRecommendations from './pages/[[lang]]/[event]/(protected)/register/other/recommendation';
import RegistrationForm from './pages/[[lang]]/[event]/(protected)/register';
import SurveyForm from './pages/[[lang]]/[event]/(protected)/survey';
import { initSentry } from '../../sentry';
import TicketingIndex from './pages/[[lang]]/[event]/ticketing/(stripe)';
import { StripeContainer } from './components/ticketing/StripeContainer';
import TicketingPayment from './pages/[[lang]]/[event]/ticketing/(stripe)/payment';
import { ProtectedRoute } from './hooks/useAuth';
import RegistrationComplete from './pages/[[lang]]/[event]/(protected)/register/success';
import SurveyComplete from './pages/[[lang]]/[event]/(protected)/survey/success';
import UserOrders from './pages/[[lang]]/[event]/(protected)/member/orders';
import UserGodsons from './pages/[[lang]]/[event]/(protected)/member/companions';
import Login from './pages/[[lang]]/[event]/login';
import Signup from './pages/[[lang]]/[event]/signup';
import SendingToken from './pages/[[lang]]/[event]/sending-token';
import CheckinsIndex from './pages/[[lang]]/[event]/checkins';
import OnlineCheckin from './pages/[[lang]]/[event]/checkins/[id]';
import CancelRegistration from './pages/[[lang]]/[event]/(protected)/member/cancel_registration';
import CustomPage from './pages/[[lang]]/[event]/pages/[shortid]/[slug]';

initSentry();

const reactMount = document.querySelector('#react-mount');

if (!reactMount) {
	throw new Error('React mount node not found.');
}

const root = createRoot(reactMount);

const perEventRoutes: RouteObject[] = [
	{ index: true, element: <Show /> },
	{ path: 'documents', element: <Documents /> },
	{
		path: 'photos',
		element: <Photos />,
	},
	{ path: 'participants', element: <Participants /> },
	{ path: 'contact', element: <Contact /> },
	{ path: 'legals', element: <Legals /> },
	{ path: 'login', element: <Login /> },
	{ path: 'signup', element: <Signup /> },
	{ path: 'sending-token', element: <SendingToken /> },
	{ path: 'checkins', element: <CheckinsIndex /> },
	{ path: 'checkins/:id', element: <OnlineCheckin /> },
	{ path: 'pages/:shortid/:slug', element: <CustomPage /> },
	{
		path: 'member',
		element: (
			<ProtectedRoute>
				<MemberArea />
			</ProtectedRoute>
		),
	},
	{
		path: 'member/orders',
		element: (
			<ProtectedRoute>
				<UserOrders />
			</ProtectedRoute>
		),
	},
	{
		path: 'member/companions',
		element: (
			<ProtectedRoute>
				<UserGodsons />
			</ProtectedRoute>
		),
	},
	{
		path: 'member/cancel_registration',
		element: (
			<ProtectedRoute>
				<CancelRegistration />
			</ProtectedRoute>
		),
	},
	{
		path: 'sharedfiles',
		element: (
			<ProtectedRoute>
				<SharedFiles />
			</ProtectedRoute>
		),
	},
	{
		path: 'register',
		element: (
			<ProtectedRoute>
				<RegistrationForm />
			</ProtectedRoute>
		),
	},
	{
		path: 'register/other',
		element: (
			<ProtectedRoute>
				<GodsonsAndRecommendations />
			</ProtectedRoute>
		),
	},
	{
		path: 'register/other/godsons',
		element: (
			<ProtectedRoute>
				<AddOrEditGodson />
			</ProtectedRoute>
		),
	},
	{
		path: 'register/other/godsons/:id',
		element: (
			<ProtectedRoute>
				<EditGodson />
			</ProtectedRoute>
		),
	},
	{
		path: 'register/other/recommendation',
		element: (
			<ProtectedRoute>
				<AddRecommendations />
			</ProtectedRoute>
		),
	},
	{
		path: 'ticketing',
		element: <StripeContainer />,
		children: [
			{
				index: true,
				element: (
					<ProtectedRoute>
						<TicketingIndex />
					</ProtectedRoute>
				),
			},
			{
				path: 'payment',
				element: (
					<ProtectedRoute>
						<TicketingPayment />
					</ProtectedRoute>
				),
			},
		],
	},
	{
		path: 'register/success',
		element: (
			<ProtectedRoute>
				<RegistrationComplete />
			</ProtectedRoute>
		),
	},
	{
		path: 'survey',
		element: (
			<ProtectedRoute>
				<SurveyForm />
			</ProtectedRoute>
		),
	},
	{
		path: 'survey/success',
		element: (
			<ProtectedRoute>
				<SurveyComplete />
			</ProtectedRoute>
		),
	},
];

const router = createBrowserRouter([
	{
		path: '/:eventSlug',
		element: <Layout />,
		children: perEventRoutes,
	},
	{
		path: '/:lang/:eventSlug',
		element: <Layout />,
		children: perEventRoutes,
	},
]);

const queryClient = new QueryClient();

root.render(
	<StrictMode>
		<QueryClientProvider client={queryClient}>
			<RouterProvider
				router={router}
				future={{
					v7_startTransition: true,
				}}
			/>
		</QueryClientProvider>
	</StrictMode>
);

import { useAbsolutePath } from '../../hooks/useAbsolutePath';
import { useEvent } from '../../hooks/useEvent';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { PrettyDate } from '../../../../shared/components/PrettyDate';

export const DetailsBlock = () => {
	const { __, lang } = useTranslate();
	const { prefix } = useAbsolutePath();
	const event = useEvent();

	const eventHasDate = event.start_date !== null && event.start_date !== '';
	const eventHasLocation = event.address_name || event.address || event.city;

	if (!eventHasDate && !eventHasLocation) {
		return null;
	}

	return (
		<section id="details">
			<div className="container section-container custom-font-content">
				<ul>
					{eventHasDate && (
						<li>
							<span className="icon">
								<i className="fa fa-calendar" aria-hidden="true"></i>
							</span>
							<span className="content">
								<PrettyDate
									start={event.start_date}
									end={event.end_date}
									all_day={event.all_day}
									__={__}
									lang={lang}
								/>
							</span>
						</li>
					)}

					{eventHasLocation && (
						<li>
							<span className="icon">
								<i className="fa fa-map-marker" aria-hidden="true"></i>
							</span>
							<span className="content">
								{event.address_name && (
									<>
										{event.address_name} <br />
									</>
								)}
								{event.address && (
									<>
										{event.address} <br />
									</>
								)}
								{event.zip} {event.city}
								{event.country && <> - {event.country}</>}
							</span>
						</li>
					)}
				</ul>

				{eventHasDate && event.calendar_link_enabled && (
					<div className="add-calendar text-center">
						<a
							href={`${prefix}/events/calendar`}
							target="_blank"
							rel="noopener noreferrer"
						>
							{__('Add to my calendar')}
						</a>
					</div>
				)}
			</div>
		</section>
	);
};

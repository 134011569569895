import { z } from 'zod';
import { TranslateFn } from '../../i18n';

export type ValidationErrors = Record<string, string>;

const convertZodErrorToValidationError = (
	zodError: z.ZodError
): ValidationErrors => {
	const validationErrors: ValidationErrors = {};

	const fieldErrors = zodError.flatten().fieldErrors;
	for (const [key, errors] of Object.entries(fieldErrors)) {
		if (Array.isArray(errors)) {
			validationErrors[key] = errors[0];
		}
	}

	return validationErrors;
};

const createFormErrorMap =
	(__: TranslateFn): z.ZodErrorMap =>
	(issue, ctx) => {
		if (issue.code === z.ZodIssueCode.too_small) {
			return { message: __('Field is required.') };
		}

		return { message: ctx.defaultError };
	};

export const validateFormData = (
	formData: unknown,
	zodSchema: z.Schema,
	__: TranslateFn
) => {
	const res = zodSchema.safeParse(formData, {
		errorMap: createFormErrorMap(__),
	});

	if (res.success) {
		return null;
	}

	return convertZodErrorToValidationError(res.error);
};

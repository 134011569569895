import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Config } from '../../types/magicform';
import { useApi } from '../../hooks/useApi';
import { QUERY_KEY } from './useFormQuery';

export function useSubmitForm(config: Config) {
	const api = useApi();
	const queryClient = useQueryClient();

	return useMutation({
		mutationFn: (items: Record<string, any>) => {
			return api.submitForm(
				config.userId ?? '',
				items,
				config.formId,
				config.presetValues?.['User.mail'] ?? null
			);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
		},
	});
}

import ReCAPTCHA from 'react-google-recaptcha';
import FormGroup from '../../../../shared/components/FormGroup';
import { useTranslate } from '../../../../shared/hooks/useTranslate';
import { FormEvent, createRef, useState } from 'react';
import { GOOGLE_CLIENT_KEY } from '../../config';
import Alert from '../Alert';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getServerErrorMessage } from '../../../../shared/utils/errors';
import axios from 'axios';
import { useAbsolutePath } from '../../hooks/useAbsolutePath';
import { useNavigate } from 'react-router-dom';
import { SubmitButton } from '../SubmitButton';
import { getCakeLang } from '../../../../i18n';

export const RegisterForm = () => {
	const { __, lang } = useTranslate();
	const { prefix } = useAbsolutePath();
	const navigate = useNavigate();

	const recaptchaRef = createRef<ReCAPTCHA>();

	const [error, setError] = useState<string | null>(null);

	const resetError = () => {
		setError(null);
	};

	const [mail, setMail] = useState('');
	const [firstname, setFirstname] = useState('');
	const [lastname, setLastname] = useState('');
	const [privacyPolicyCheckbox, setPrivacyPolicyCheckbox] = useState(false);

	const queryClient = useQueryClient();
	const sendRegisterFormMutation = useMutation({
		mutationFn: async (formData: {
			recaptcha: string;
			mail: string;
			firstname: string;
			lastname: string;
			terms: boolean;
		}) => {
			await axios.post(`${prefix}/users/loginRegisterJson`, formData);
		},
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['event_auth'] });
			navigate(`${prefix}/register`);
		},
		onError: (err) => {
			setError(getServerErrorMessage(err, err.message));
		},
		onSettled: () => {
			recaptchaRef.current?.reset();
		},
	});

	const handleSubmit = (e: FormEvent) => {
		e.preventDefault();

		resetError();

		if (!privacyPolicyCheckbox) {
			setError(
				__(
					'Please indicate that you have read and agree to the Privacy Policy.'
				)
			);
			return;
		}

		const recaptcha = recaptchaRef.current?.getValue();

		if (!recaptcha) {
			setError(__('Please fill the captcha correctly.'));
			return;
		}

		sendRegisterFormMutation.mutate({
			recaptcha,
			mail,
			firstname,
			lastname,
			terms: privacyPolicyCheckbox,
		});
	};

	return (
		<form className="form" onSubmit={handleSubmit}>
			<h2 className="form-title">{__('Do you wish to participate?')}</h2>

			{error && (
				<Alert type="danger" dismissible>
					{error}
				</Alert>
			)}

			<FormGroup label={__('Email')} name="mail" required>
				<div className="form-col">
					<input
						name="mail"
						type="email"
						className="form-control"
						value={mail}
						onChange={(e) => {
							setMail(e.target.value);
						}}
					/>
				</div>
			</FormGroup>

			<FormGroup label={__('First Name')} name="firstname" required>
				<div className="form-col">
					<input
						name="firstname"
						type="text"
						className="form-control"
						value={firstname}
						onChange={(e) => {
							setFirstname(e.target.value);
						}}
					/>
				</div>
			</FormGroup>

			<FormGroup label={__('Last Name')} name="lastname" required>
				<div className="form-col">
					<input
						name="lastname"
						type="text"
						className="form-control"
						value={lastname}
						onChange={(e) => {
							setLastname(e.target.value);
						}}
					/>
				</div>
			</FormGroup>

			<div className="form-group">
				<legend className="control-label"></legend>
				<div className="form-col">
					<div className="checkbox">
						<label>
							<input
								name="terms"
								type="checkbox"
								checked={privacyPolicyCheckbox}
								onChange={(e) => {
									setPrivacyPolicyCheckbox(e.target.checked);
								}}
							/>
							{__('By checking this case, I acknowledge having read the')}{' '}
							<a
								href={`/files/legal/pp_${getCakeLang(lang)}.pdf`}
								target="_blank"
								rel="noreferrer"
							>
								{__('Privacy Policy')}
							</a>
							.
						</label>
					</div>
				</div>
			</div>

			<div className="form-group">
				<label className="control-label"></label>
				<div className="form-col">
					<ReCAPTCHA sitekey={GOOGLE_CLIENT_KEY} ref={recaptchaRef} />
				</div>
			</div>

			<div className="row">
				<div className="col-sm-12">
					<div className="form-inline text-right">
						<div className="form-group form-action">
							<SubmitButton loading={sendRegisterFormMutation.isPending}>
								{__('Continue')}
							</SubmitButton>
						</div>
					</div>
				</div>
			</div>
		</form>
	);
};

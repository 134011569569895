import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import ErrorBoundary from './components/ErrorBoundary';
import { TranslateProvider } from '../../shared/hooks/useTranslate';
import { EventProvider } from './hooks/useEvent';
import { Outlet, useParams, ScrollRestoration } from 'react-router-dom';
import { AuthProvider } from './hooks/useAuth';
import { LayoutConfigProvider } from './hooks/useLayoutConfig';
import AdminNavbar from './components/layout/AdminNavbar';
import Nav from './components/layout/Nav';
import ArchivedBanner from './components/layout/ArchivedBanner';
import Footer from './components/layout/Footer';
import { ApiProvider } from './hooks/useApi';
import { EditorContainer } from './components/editor/Editor';
import { DesignProvider } from './hooks/useDesign';
import { ToastContainer } from './hooks/useToast';
import { useIsCustomizePreview } from './hooks/useIsCustomizePreview';

const Layout = () => {
	const { lang, eventSlug } = useParams();
	const isCustomizePreview = useIsCustomizePreview();

	if (!eventSlug) {
		throw new Error('Event must be set.');
	}

	return (
		<ErrorBoundary>
			<ToastContainer autoClose>
				<TranslateProvider routeLang={lang}>
					<LayoutConfigProvider>
						<AuthProvider>
							<ApiProvider>
								<EventProvider eventSlug={eventSlug} lang={lang}>
									<DesignProvider>
										<AdminNavbar />
										<EditorContainer>
											<Nav />
											<div
												id="start"
												style={{
													pointerEvents: isCustomizePreview
														? 'none'
														: undefined,
												}}
											>
												<Outlet />
											</div>
											<ArchivedBanner />
											<Footer />
										</EditorContainer>
										<ScrollRestoration />
									</DesignProvider>
								</EventProvider>
							</ApiProvider>
						</AuthProvider>
					</LayoutConfigProvider>
					<ReactQueryDevtools />
				</TranslateProvider>
			</ToastContainer>
		</ErrorBoundary>
	);
};

export default Layout;

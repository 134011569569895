import { User } from '../../types/ticketing';
import Api from '../../../../api';
import { useApi } from '../../hooks/useApi';
import { useQuery } from '@tanstack/react-query';

const fetchCompanions = async (api: Api, godsons: string[]) => {
	const userPromises = godsons.map((godsonId) => api.fetchUser(godsonId));
	const purchasedTicketpromises = godsons.map((godsonId) =>
		api.fetchPurchasedTickets(godsonId, { expand: 'customer' })
	);

	const userResponses = await Promise.all(userPromises);
	const purchasedTicketResponses = await Promise.all(purchasedTicketpromises);

	return godsons.map((godsonId, index) => {
		const godson = userResponses[index].data;
		godson.tickets = purchasedTicketResponses[index].data;
		return godson;
	});
};

const buildFetchUser = (api: Api) => async (id: string) => {
	const userResponse = await api.fetchUser(id);

	const ticketResponse = await api.fetchPurchasedTickets(id, {
		expand: 'customer',
	});

	const user = userResponse.data;
	user.tickets = ticketResponse.data;

	const companions = (await fetchCompanions(api, user.godsons)) as User[];

	return {
		user: user as User,
		companions,
	};
};

export const useUserQuery = (id: string) => {
	const api = useApi();

	const fetchUser = buildFetchUser(api);

	return useQuery({
		queryKey: ['users', id],
		queryFn: () => fetchUser(id),
	});
};

import { createContext, useContext } from 'react';
import Jed from 'jed';
import { LanguageShort } from '../../i18n';
import { fra, spa } from '../../translations';
import { __nProto, __Proto } from '../../i18n';

const i18nEn = new Jed({
	domain: 'messages',
	locale_data: {
		messages: {
			'': {
				domain: 'messages',
				lang: 'en',
				'plural-forms': 'nplurals=2; plural=(n != 1);',
			},
		},
	},
});
const i18nFr = new Jed({
	domain: 'messages',
	locale_data: fra,
});
const i18nSp = new Jed({
	domain: 'messages',
	locale_data: spa,
});

const TranslateContext = createContext({
	__: __Proto(i18nEn),
	__n: __nProto(i18nEn),
	lang: 'en' as LanguageShort,
	routeLang: undefined as string | undefined,
});

const useTranslate = () => useContext(TranslateContext);

const useRouteLang = () => {
	const { routeLang } = useContext(TranslateContext);
	return routeLang;
};

const TranslateProvider = ({
	routeLang,
	children,
}: {
	routeLang: string | undefined;
	children?: React.ReactNode;
}) => {
	const domLang = document.documentElement.lang;
	const lang: LanguageShort = (routeLang ?? domLang) as LanguageShort;

	const i18n = lang === 'fr' ? i18nFr : lang === 'es' ? i18nSp : i18nEn;

	return (
		<TranslateContext.Provider
			value={{
				__: __Proto(i18n),
				__n: __nProto(i18n),
				lang,
				routeLang,
			}}
		>
			{children}
		</TranslateContext.Provider>
	);
};

export { TranslateProvider, TranslateContext, useTranslate, useRouteLang };

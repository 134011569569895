import { useAbsolutePath } from '../../hooks/useAbsolutePath';
import { useMutation } from '@tanstack/react-query';
import { Checkin } from '@invityou/types';
import { useEffect } from 'react';
import axios from 'axios';

export const LiveCheckin = ({ checkin }: { checkin: Checkin }) => {
	const { prefix } = useAbsolutePath();

	const { mutate } = useMutation({
		mutationFn: async () => {
			await axios.post(`${prefix}/live_checkin`, {
				id: checkin.id,
			});
		},
	});
	useEffect(() => {
		mutate();
	}, [mutate]);

	return <div dangerouslySetInnerHTML={{ __html: checkin.message }}></div>;
};

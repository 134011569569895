import { z } from 'zod';

const LangSchema = z.union([z.literal('fr'), z.literal('en'), z.literal('es')]);

const TranslatedValueSchema = z.record(LangSchema, z.string());
const NonEmptyTranslatedValueSchema = z.record(LangSchema, z.string().min(1));

const BlockSchema = z.object({
	id: z.string(),
	element: z.object({
		type: z.literal('html'),
		content: TranslatedValueSchema,
	}),
});

export const CreatePageFormValidationSchema = z.object({
	slug: z.string().min(1),
	title: NonEmptyTranslatedValueSchema,
	nav_title: NonEmptyTranslatedValueSchema,
});

export const UpdatePageFormValidationSchema = z.object({
	slug: z.string().min(1),
	title: NonEmptyTranslatedValueSchema,
	display_nav: z.boolean(),
	nav_title: NonEmptyTranslatedValueSchema,
	blocks: z.array(BlockSchema),
});

import { User, Category, Ticket, Order } from '../../types/ticketing';
import CategoryCard from './CategoryCard';
import { useTranslate } from '../../../../shared/hooks/useTranslate';

const getSubcategoryIds = (categories: Category[]) => {
	return categories
		.flatMap((category) => category.items)
		.filter((item) => item.type === 'category')
		.map((category) => category.id);
};

const getTopLevelCategories = (categories: Category[]) => {
	const subcategories = getSubcategoryIds(categories);
	return categories
		.filter((category) => !subcategories.includes(category.id))
		.sort((a, b) => a.position - b.position);
};

const isCompanion = (user: User): boolean => {
	const companionStatus: string = user.created_source;
	return companionStatus === 'usersCompanion';
};

const UserIcon = () => {
	return (
		<svg
			id="Capa_1"
			height="50"
			viewBox="0 0 512 512"
			width="50"
			xmlns="http://www.w3.org/2000/svg"
		>
			<g>
				<path
					d="m256 0-160.398 256 160.398 256c141.385 0 256-114.615 256-256s-114.615-256-256-256z"
					fill="#28abfa"
				/>
				<path
					d="m0 256c0 141.385 114.615 256 256 256v-512c-141.385 0-256 114.615-256 256z"
					fill="#14cfff"
				/>
				<path
					d="m256 60-65.788 105 65.788 105c57.99 0 105-47.01 105-105s-47.01-105-105-105z"
					fill="#373e9f"
				/>
				<path
					d="m151 165c0 57.99 47.01 105 105 105v-210c-57.99 0-105 47.01-105 105z"
					fill="#6241ea"
				/>

				<path
					d="m424.649 335.443c-19.933-22.525-48.6-35.443-78.649-35.443h-90l-60 76 60 76c70.322 0 135.636-38.01 170.454-99.198l5.306-9.325z"
					fill="#373e9f"
				/>
				<path
					d="m166 300c-30.049 0-58.716 12.918-78.649 35.443l-7.11 8.035 5.306 9.325c34.817 61.187 100.131 99.197 170.453 99.197v-152z"
					fill="#6241ea"
				/>
			</g>
		</svg>
	);
};

interface Props {
	user: User;
	companionIndex: number;
	categories: Category[];
	tickets: Ticket[];
	ticketError: Error | null;
	order: Order;
	addTicket: (ticketId: string, userId: string) => void;
	removeTicket: (ticketId: string, userId: string) => void;
}

const UserCard = (props: Props) => {
	const {
		user,
		companionIndex,
		categories,
		tickets,
		ticketError,
		order,
		addTicket,
		removeTicket,
	} = props;

	const { __ } = useTranslate();
	return (
		<div className={`banner ticketing_user_active ticketing_user`}>
			<div className="container" style={{ padding: 0 }}>
				<div className="row">
					<div className="col-md-4">
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
								marginBottom: 20,
								marginTop: 20,
							}}
						>
							<UserIcon />
							{!isCompanion(user) ? (
								<span>
									{user.firstname} {user.lastname} ({__('You')})
								</span>
							) : (
								<div style={{ paddingTop: 10, textAlign: 'center' }}>
									<span>
										{`${__('Companion')} ${companionIndex}`}
										<br />
									</span>
									<div
										style={{
											marginTop: 15,
											marginRight: 'auto',
											marginLeft: 'auto',
											width: 'max-content',
										}}
									>
										<div style={{ textAlign: 'center' }}>
											{user.firstname}&nbsp; {user.lastname}
										</div>

										<div style={{ textAlign: 'center' }}>{user.mail}</div>
									</div>
								</div>
							)}
						</div>
					</div>
					<div className="col-md-8" style={{ position: 'relative' }}>
						<div className="categories_user">
							{getTopLevelCategories(categories).map((category) => (
								<CategoryCard
									key={category.id}
									category={category}
									categories={categories}
									tickets={tickets}
									ticketError={ticketError}
									user={user}
									order={order}
									addTicket={addTicket}
									removeTicket={removeTicket}
								/>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default UserCard;

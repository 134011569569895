import { isSameDay, parseISO } from 'date-fns';
import { LanguageShort, TranslateFn } from '../../i18n';

const capitalize = (string: string) => {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

interface PrettyDateProps {
	start: string | null;
	end: string | null;
	all_day: boolean;
	__: TranslateFn;
	lang: LanguageShort;
}

export const PrettyDate = ({
	start,
	end,
	all_day,
	__,
	lang,
}: PrettyDateProps) => {
	if (start && end) {
		const startDate = parseISO(start);
		const endDate = parseISO(end);

		if (isSameDay(startDate, endDate)) {
			return (
				<span>
					{all_day
						? __(
								'%s',
								capitalize(
									startDate.toLocaleString(lang, {
										day: 'numeric',
										weekday: 'long',
										month: 'long',
										year: 'numeric',
									})
								)
							)
						: __(
								'%s from %s to %s',
								capitalize(
									startDate.toLocaleString(lang, {
										day: 'numeric',
										weekday: 'long',
										month: 'long',
										year: 'numeric',
									})
								),
								startDate.toLocaleString(lang, {
									hour: 'numeric',
									minute: 'numeric',
								}),
								endDate.toLocaleString(lang, {
									hour: 'numeric',
									minute: 'numeric',
								})
							)}
				</span>
			);
		}

		return all_day ? (
			<span>
				{__(
					'From %s',
					startDate.toLocaleString(lang, {
						day: 'numeric',
						month: 'long',
						year: 'numeric',
					})
				)}
				<br />
				{__(
					'To %s',
					endDate.toLocaleString(lang, {
						day: 'numeric',
						month: 'long',
						year: 'numeric',
					}),
					endDate.toLocaleString(lang, {
						hour: 'numeric',
						minute: 'numeric',
					})
				)}
			</span>
		) : (
			<span>
				{__(
					'From %s at %s',
					startDate.toLocaleString(lang, {
						day: 'numeric',
						month: 'long',
						year: 'numeric',
					}),
					startDate.toLocaleString(lang, {
						hour: 'numeric',
						minute: 'numeric',
					})
				)}

				<br />
				{__(
					'To %s at %s',
					endDate.toLocaleString(lang, {
						day: 'numeric',
						month: 'long',
						year: 'numeric',
					}),
					endDate.toLocaleString(lang, {
						hour: 'numeric',
						minute: 'numeric',
					})
				)}
			</span>
		);
	}

	if (start) {
		const startDate = parseISO(start);

		const first = startDate.toLocaleString(lang, {
			weekday: 'long',
			day: 'numeric',
			month: 'long',
			year: 'numeric',
		});

		return (
			<span>
				{all_day
					? __('%s', capitalize(first))
					: __(
							'%s at %s',
							capitalize(first),
							startDate.toLocaleString(lang, {
								hour: 'numeric',
								minute: 'numeric',
							})
						)}
			</span>
		);
	}

	return null;
};

import { useCustomQueryParams } from './useCustomQueryParams';

export const usePageQueryParams = () => {
	return useCustomQueryParams(
		'page',
		'1',
		(input) => Number.parseInt(input, 10),
		(input) => input.toString()
	);
};
